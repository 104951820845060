import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    codeArea: null,
}

const areaSlice = createSlice({
    name: 'area',
    initialState,
    reducers: {
        setCodeArea: (state, action) => {
            state.codeArea = action.payload;
        }
    }
})

export const { setCodeArea } = areaSlice.actions;
export default areaSlice.reducer