import tw from "tailwind-styled-components";

export const FormWrapper = tw.div`
    flex 
    justify-between 
    px-[20px] 
    pt-[25px]
    pb-[25px]
    mb-[30px]
`
export const TitileHeading = tw.span`
    text-[20px] 
    font-bold 
    text-primary
`
export const FormInfoUser = tw.div`
    flex 
    items-center
`
export const FormToggle = tw.div`
    mt-[20px]
    mb-[20px]
`
export const ButtonUser = tw.button`
    px-[35px]
    py-[8px]
    text-[17px]
    font-bold
    text-black
    rounded-[7px]
    border-[1px]
    border-black
    mr-[20px]
`
export const ButtonShop = tw.button`
    px-[35px]
    py-[8px]
    text-[17px]
    font-bold
    text-black
    rounded-[7px]
    border-[1px]
    border-black
    mr-[20px]
`

// text-white
//     rounded-[7px]
//     bg-gradient-to-r from-[#BF36DF] to-[#FF018B]