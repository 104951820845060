import AxiosClient from "./axiosClient";

const API_ENDPOINT = "/user";

export const getListUser = (params) => {
  return AxiosClient.get(API_ENDPOINT, { params: params });
};

export const getListUserByArea = (codeArea) => {
  return AxiosClient.get(API_ENDPOINT, { params: { codeArea: codeArea } });
}

export const createShipper = (formRegister) => {
  return AxiosClient.post(API_ENDPOINT + `/createShipper`, formRegister);
}

export const changeSttUser = (id, data) => {
  return AxiosClient.put(API_ENDPOINT + `/status/${id}`, { "status": data });
};

export const createUserLocal = (data) => {
  return AxiosClient.post('user/create', data)
}



