import React, { useState, useEffect, useContext } from 'react';
import Header from './Header';
import ReportFilter from './ReportFilter';
import ReportChart from './ReportChart';
import { randomRgba } from "../../../helper/functions";
import { Loading } from "notiflix";
import { format } from "date-fns";
import { MedalContext } from '../../../context/Context'
import { getReportShop, getReportShopShop, getReportUser } from "../../../services/reportServices";
import { useSelector } from 'react-redux';

function ReportChartShop() {
    const user = useSelector((state) => state.auth.user);
    const [listReport, setListReport] = useState([]);
    const [listReportShop, setListReportShop] = useState([]);
    const [listReportUser, setListReportUser] = useState([]);
    const [fillterName, setFillterName] = useState()
    const { setFillterDate, fillterDate } = useContext(MedalContext)
    console.log(fillterDate);

    const getData = () => {
        Loading.pulse();
        const dataParams = {
            startDate: format(new Date(startDate), "yyyy-MM-dd"),
            endDate: format(new Date(endDate), "yyyy-MM-dd"),
            name: fillterName,
            codeArea: user?.codeArea,
        };
        setFillterDate(dataParams)
        if (dataParams) {
            // get report all
            getReportShop(dataParams)
                .then((res) => {
                    console.log('Tổng đơn các Shop: ', res);
                    setListReport(res?.data);
                })
                .catch((err) => console.log(err))
                .finally(() => Loading.remove());
            // get report shop
            getReportShopShop(dataParams)
                .then((res) => {
                    console.log('Tổng đơn shop tạo:', res);
                    setListReportShop(res?.data);
                })
                .catch((err) => console.log(err))
                .finally(() => Loading.remove());
            // get report user
            getReportUser(dataParams)
                .then((res) => {
                    console.log('Tổng đơn khách tạo:', res);
                    setListReportUser(res?.data);
                })
                .finally(() => Loading.remove());
        } else {
            return
        }
    };

    useEffect(() => {
        getData();
    }, []);

    const onPressedFilter = () => {
        getData();
    };

    const date = new Date(),
        y = date.getFullYear(),
        m = date.getMonth();
    const [startDate, setStartDate] = useState(new Date(y, m, 1));
    const [endDate, setEndDate] = useState(new Date(y, m + 1, 0));

    return (
        <div className="report-pane">
            <ReportFilter
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                onPressedFilter={onPressedFilter}
                type
                setFillterName={setFillterName}
                fillterName={fillterName}
                getData={getData}
            />
            <div className="h-[500px] overflow-y-scroll">
                <ReportChart report={listReport} />
                <ReportChart report={listReportShop} type='Tổng đơn shop tạo' />
                <ReportChart report={listReportUser} type='Tổng đơn khách tạo' />
            </div>
        </div>
    )
}

export default ReportChartShop