import firebase from 'firebase/compat/app';
// import 'firebase/compat/messaging';
// import 'firebase/compat/auth';
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getMessaging , onMessage  } from "firebase/messaging";
const firebaseConfig = {
    apiKey: "AIzaSyANRSASL8fO2VBqCOqAsR4zxI-V9pyTKdg",
    authDomain: "bunny-ship.firebaseapp.com",
    databaseURL: "https://bunny-ship-default-rtdb.firebaseio.com",
    projectId: "bunny-ship",
    storageBucket: "bunny-ship.appspot.com",
    messagingSenderId: "306248385097",
    appId: "1:306248385097:web:248787875ca7f523abb598",
    measurementId: "G-L3NP70J1DK"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const messaging = getMessaging(app);
export { firebase,auth ,messaging};

// notification
// export const messaging = firebase.messaging();
export const getToken = async (setTokenFound) => {
  let currentToken = "";
  try {
    currentToken = getToken(messaging,{ vapidKey: 'BLqSs5cqFv2IpNgchCfWULuMR7rOlIjzFjSBBpLccKjcQ-b7DuEjS-6HyVx2ZW7ZORFytDzr0w-hLeBJP4GDRHA' });
    console.log(currentToken)
    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }

  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging,(payload) => {
      resolve(payload);
    });
  });