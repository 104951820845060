import React, { useContext, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { MedalContext } from "../../context/Context";
import { format } from "date-fns";
import { updateOrder } from "../../services/orderServices";
import { useSelector } from "react-redux";
import { MdDeleteForever } from "react-icons/md";
import Modal from "react-bootstrap/Modal";

import deleteIcon from "../../assets/icon/deleteIcon.png";
import eyeIcon from "../../assets/icon/eyeIcon.png";
import editIcon from "../../assets/icon/editIcon.png";
import downIcon from "../../assets/icon/downIcon.png";
import dropDown from "../../assets/icon/dropDown.png";
import {
  FormOderItem, Code, Product, Route, Pay, Note, Status,
  CodeNumber, Time, Receiver, From, To, FormTitile,
  FormAddress, ToTitile, ToAddress, ReceiverTitle, ReceiverInfo, ReceiverInfoName,
  ReceiverInfoPhone, Length, LengthTitle, LengthContext, Ship, ShipTitle, ShipContext,
  ShipPlus, Money, MoneyTitle, MoneyContext, Total, TotalTitle, TotalContext,
  StatusBtn, StatusBtnTitle, StatusBtnImg, Dropdowns, DropdownImg, TitleStatus, FormName,
} from "./OderItem.styles";
import { set } from "date-fns/esm";

function OderItem({ oder }) {
  const {
    setIsModalEditVisible,
    setIsModalVisible,
    setOrder,
    setIdUpdate,
    setReRender,
  } = useContext(MedalContext);

  const handleShowEdit = (oder) => {
    setIdUpdate(oder._id);
    setOrder(oder);
    setIsModalEditVisible(true);
  };
  const handleShowDetail = (oder) => {
    setOrder(oder);
    setIsModalVisible(true);
  };
  const handleUpdateStatus = (type) => {
    const data = {
      from: oder.from,
      to: oder.to,
      fullName: oder.fullName,
      phone: oder.phone,
      note: oder.note,
      status: type,
      distance: oder.distance,
    };
    updateOrder(oder._id, data).then(() => {
      setReRender(Math.random());
    });
  };
  // format money
  var formatter = new Intl.NumberFormat("vi", {
    style: "currency",
    currency: "VND",
  });
  // dropdown status
  const dropdownStatus = [
    {
      type: "PENDDING",
      text: "Chờ xác nhận",
      color: "text-[#0A00FF]",
    },
    {
      type: "WAITING_FOR_PRODUCT",
      text: "Chờ lấy hàng",
      color: "text-[#F9128F]",
    },
    {
      type: "DELIVERY",
      text: "Đang giao",
      color: "text-[#F67A28]",
    },
    {
      type: "COMPLETED",
      text: "Đã giao",
      color: "text-[#0FE416]",
    },
    {
      type: "CANCELLED",
      text: "Huỷ đơn",
      color: "text-[#F41D1D]",
    },
  ];


  // handle show status
  const showStatus = (status) => {
    switch (status) {
      case "PENDDING":
        return <span className="text-[#0A00FF]">Chờ xác nhận</span>;
      case "WAITING_FOR_PRODUCT":
        return <span className="text-[#F9128F]">Chờ lấy hàng</span>;
      case "DELIVERY":
        return <span className="text-[#F67A28]">Đang giao</span>;
      case "COMPLETED":
        return <span className="text-[#0FE416]">Đã giao</span>;
      case "CANCELLED":
        return <span className="text-[#F41D1D]">Huỷ đơn</span>;
      default:
        break;
    }
  };
  // rerender list order 5s
  // get role
  const role = useSelector((state) => state.role.role);
  // handle modal call huy don shop
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleTotalPrice = () => {
    if (oder.type !== "SHIP") {
      const calculateDiscountedTotal = (total, discount) => Math.max(total - discount, 0);
      const totalPriceShop = calculateDiscountedTotal(oder?.totalPrice, oder?.voucherShopPrice ? oder?.voucherShopPrice : 0);
      const totalPriceShip = calculateDiscountedTotal(oder?.shipPrice, oder?.voucherAdminPrice ? oder?.voucherAdminPrice : 0);
      const totalVoucherPrice = totalPriceShop + totalPriceShip + oder?.serviceFee;
      return totalVoucherPrice;
    } else {
      return oder?.totalPrice;
    }
  }
  return (
    <FormOderItem>
      {/* mã đơn */}
      <Code>
        <CodeNumber>{oder?.code}</CodeNumber>
        <Time>{format(new Date(oder?.createdAt), "HH:mm")}</Time>
        <Time>{format(new Date(oder?.createdAt), "yyyy-MM-dd")}</Time>
        <span className="mt-[17px] font-bold text-primary">
          {oder.type === "USER"
            ? "ĐƠN KHÁCH TẠO"
            : oder.type === "SHIP"
              ? "ĐƠN GIAO HỘ"
              : "ĐƠN SHOP TẠO"}
        </span>
      </Code>
      {/* mã sản phẩm */}
      <Product>
        <ul>
          {oder?.details?.map((item, index) => (
            <li key={index}>
              x{item.amount} {item.productName}
            </li>
          ))}
        </ul>
      </Product>
      {/* lộ trình */}
      <Route>
        <From>

          <FormTitile>Điểm lấy</FormTitile>
          <div>
            {
              oder?.type !== "SHIP" &&
              <span>Shop: </span>
            }
            <FormName>{oder?.shop?.name}</FormName>
          </div>
          <FormAddress>{oder?.to}</FormAddress>
          <ReceiverInfoPhone>{oder?.shop?.user?.phone ? oder?.shop?.user?.phone : oder.user.phone}</ReceiverInfoPhone>
        </From>
        <To>
          <ToTitile>Điểm giao</ToTitile>
          <ToAddress>{oder?.from}</ToAddress>
        </To>
        <Receiver>
          <ReceiverTitle>Người gửi:</ReceiverTitle>
          <ReceiverInfo>
            <ReceiverInfoName>{oder?.fullName}</ReceiverInfoName>
            <ReceiverInfoPhone>{oder?.phone}</ReceiverInfoPhone>
          </ReceiverInfo>
        </Receiver>
      </Route>
      {/* thanh toán */}
      <Pay>
        {
          oder?.type !== "SHIP" && (
            <Money>
              <MoneyTitle>Tiền hàng:</MoneyTitle>
              <MoneyContext>{formatter.format(oder?.totalPrice)}</MoneyContext>
            </Money>
          )
        }
        <Ship>
          <ShipTitle>Phí ship: </ShipTitle>
          <div>
            <ShipContext>{formatter.format(oder?.shipPrice)}</ShipContext>
            {oder?.isRainy && <ShipPlus>+ 20% trời mưa</ShipPlus>}
          </div>
        </Ship>

        {
          oder.serviceFee ? (
            <Money>
              <MoneyTitle>Phí dịch vụ:</MoneyTitle>
              <MoneyContext>{formatter.format(oder?.serviceFee)}</MoneyContext>
            </Money>
          ) : null
        }

        {
          oder?.type !== "SHIP" && (
            <>
              {
                oder?.voucherShopPrice ? (
                  <Money>
                    <MoneyTitle>Voucher shop giảm:</MoneyTitle>
                    <MoneyContext>{"-" + formatter.format(oder?.voucherShopPrice)}</MoneyContext>
                  </Money>
                ) : null
              }
              {
                oder?.voucherAdminPrice ? (
                  <Money>
                    <MoneyTitle>Freeship giảm:</MoneyTitle>
                    <MoneyContext>{"-" + formatter.format(oder?.voucherAdminPrice)}</MoneyContext>
                  </Money>
                ) : null
              }
            </>
          )
        }
        <Length>
          <LengthTitle>Quãng đường:</LengthTitle>
          <LengthContext>{oder?.distance}km</LengthContext>
        </Length>
        <Total>
          <TotalTitle>Tổng tiền:</TotalTitle>
          <TotalContext>
            {
              formatter.format(handleTotalPrice())
            }
          </TotalContext>
        </Total>
      </Pay>
      {/* chi chú */}
      <Note>
        <span>{oder?.note}</span>
      </Note>
      {/* trạng thái */}
      <Status>
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            <StatusBtn>
              <StatusBtnTitle>{showStatus(oder?.status)}</StatusBtnTitle>
              <StatusBtnImg src={downIcon} />
            </StatusBtn>
          </Dropdown.Toggle>

          {(role == "ADMIN" || role == "SUPERADMIN") && (
            <Dropdown.Menu>
              {dropdownStatus.map((status, index) => (
                <span key={index}>
                  <Dropdown.Item
                    key={index}
                    onClick={() => handleUpdateStatus(status.type)}
                  >
                    <TitleStatus className={status.color}>
                      {status.text}
                    </TitleStatus>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                </span>
              ))}
            </Dropdown.Menu>
          )}
        </Dropdown>
        <Dropdowns>
          {/* <DropdownImg src={dropDown} /> */}
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <DropdownImg src={dropDown} />
            </Dropdown.Toggle>

            {(role == "ADMIN" || role == "SUPERADMIN") && (
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleShowEdit(oder)}>
                  <div className="flex">
                    <img
                      className="w-[20px] h-[20px] mr-[15px]"
                      src={editIcon}
                    />
                    <span>Chỉnh sửa</span>
                  </div>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => handleShowDetail(oder)}>
                  <div className="flex">
                    <img
                      className="w-[20px] h-[15px] mr-[15px]"
                      src={eyeIcon}
                    />
                    <span>Xem chi tiết</span>
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            )}
            {role == "SHOP" && (
              <Dropdown.Menu>
                <span>
                  <Dropdown.Item onClick={handleShow}>
                    <div className="flex justify-between">
                      <TitleStatus className="text-[]">Hủy đơn</TitleStatus>
                      <MdDeleteForever className="text-[20px] text-[#FF0000]" />
                    </div>
                  </Dropdown.Item>
                </span>
              </Dropdown.Menu>
            )}
          </Dropdown>
        </Dropdowns>
        {
          <Modal show={show} onHide={handleClose} centered={true} size="md">
            <div className="px-[30px] py-[20px] flex flex-col items-center">
              <span className="text-[20px]">XÁC NHẬN HỦY ĐƠN</span>
              <span className="text-[18px] text-center w-[300px] my-[20px]">
                Bạn thật sự muốn hủy đơn hàng này. Liên hệ chúng tôi ngay
              </span>
              <span className="text-[#EF0383] text-[18px] font-medium">
                0856868946
              </span>
              <button
                className="text-[18px font-medium] bg-gradient-to-b from-[#FF6464] to-[#FF008A] text-[#fff] rounded-md px-[35px] py-[15px] mt-[30px]"
                onClick={handleClose}
              >
                Xác Nhận
              </button>
            </div>
          </Modal>
        }
      </Status>
    </FormOderItem>
  );
}

export default OderItem;
