import tw from "tailwind-styled-components";

export const DPR = {};

DPR.DatePickerHeader = tw.div`
  py-[10px]
  px-[19px]
  flex
  items-center
  justify-between
  border-b
  border-b-[#aaa]
`;

DPR.DatePickerHeaderTitle = tw.div`
  text-[17px]
  font-bold
  mr-auto
`;

DPR.DatePickerHeaderButtons = tw.div`
  flex
`;

DPR.DatePickerHeaderButton = tw.button`
  w-[40px]
  h-[30px]
  flex
  items-center
  justify-center
  border
  border-[#D5D5D5]
  first:rounded-l-md
  last:rounded-r-md
`;

DPR.DatePickerInput = tw.button`
  w-[200px]
  h-[40px]
  border
  border-[#aaa]
  flex
  justify-center
  items-center
  gap-[15px]
  px-[20px]
  rounded-md
  bg-white
`;

DPR.DatePickerValue = tw.p`
  text-[17px]
  mr-auto
  tracking-widest
`;
