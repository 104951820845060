import React, { useEffect, useContext } from 'react'
import Header from './components/Header'
import TabUser from './components/TabUser'
import TabShop from './components/TabShop'
import TabShipper from './components/TabShipper';

const $ = document.querySelector.bind(document);
const $$ = document.querySelectorAll.bind(document);

function AccountManager() {
  useEffect(() => {
    $$('.ManagerBtn').forEach((item, index) => {
      item.addEventListener('click', () => {

        $('.ManagerBtn.active').classList.remove('active');
        item.classList.add('active');

        $('.manager-pane.active').classList.remove('active');
        $$('.manager-pane')[index].classList.add('active');
      })
    });
  }, [])


  return (
    <div className="bg-[#F5F6FA] h-full flex flex-col">
      <Header />
      <TabUser />
      <TabShop />
      <TabShipper />
    </div>
  )
}

export default AccountManager