import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./auth/authSlice";
import permissionSlice from "./permission/permissionSlice";
import renderSlice from './Rerender/RerenderSlice';
import roleSlice from "./role/role"
import productSlice from './product/productSlice'
import areaSlice from './area/area'

const store = configureStore({
  reducer: {
    auth: authSlice,
    permission: permissionSlice,
    render: renderSlice,
    role: roleSlice,
    product: productSlice,
    area: areaSlice,
  },
});

export default store;
