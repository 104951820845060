import tw from 'tailwind-styled-components'

export const FormWrapper = tw.div`
    h-[650px]
    overflow-y-auto
    w-full
    px-[50px]
    gap-[30px]
    flex 
    flex-col
    justify-between
`
export const FormWrapperType = tw.div`
    h-fit
    w-full
    overflow-y-auto
    px-[10px]
`
export const Item = tw.div`
    flex
    items-center
    mb-[30px]
`
export const ItemTitle = tw.span`
    font-bold
    mr-[30px]
    w-[100px]
`
export const FormInput = tw.div`
    border-b-[1px]
    border-b-[#707070]
    w-full
    flex
`
export const InputTxt = tw.textarea`
    w-full
    border-none
    outline-none
`
export const Input = tw.input`
    w-full
    border-none
    outline-none
`
export const StatusBtn = tw.div`
    flex
    items-center
    justify-between
    w-[240px]
    h-[39px]
    px-[12px]
    border-2
    border-[#707070]
    rounded-[7px]
    hover:cursor-pointer
`
export const StatusBtnTitle = tw.div`
    font-bold
    mr-[10px]
`
export const StatusBtnImg = tw.img`
    w-[15px]
    h-[6px]
`
export const FormStatus = tw.div`
    w-full
`
export const FormBtn = tw.div`
    w-full
    flex
    justify-center
    items-center
    px-[20px]
`
export const Button = tw.button`
    px-[40px]
    py-[10px]
    text-[17px]
    font-bold
    text-white
    rounded-default
    bg-gradient-to-r from-[#BF36DF] to-[#FF018B]
`
export const TitleStatus = tw.span`
    font-bold 
    text-[14px]
`
export const Span = tw.span`
    font-bold 
    text-[14px]
`