import React, { useContext, useState, useEffect } from 'react';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { getNotificationsInfo } from '../../../services/notificationService'
import { Modal } from 'antd';
import { MedalContext } from '../../../context/Context'
import upImg from '../../../assets/icon/upImg.png';
import { postNotifications, putNotifications } from '../../../services/notificationService';
import Notiflix from 'notiflix';

import {
    FormWrapper,
    BtnImg,
    Img,
    BoxTitle,
    Title,
    Input,
    Textarea,
    Note,
    Button,
    BoxButton,
} from './ModalCreateNotify.styles'
import './Modals.scss';

function ModalCreateNotify({ type, notiContext, getlist }) {

    const { isModalCreateNotifyVisible, setIsModalCreateNotifyVisible } = useContext(MedalContext);
    const [imgNoti, setImgNoti] = useState(upImg);
    const [fileImg, setFileImg] = useState();
    const [title, setTitle] = useState();
    const [content, setContent] = useState();
    const [id, setId] = useState();

    useEffect(() => {
        if (type == "edit") {
            let fetch_blob = fetch(notiContext.photo).then(r => r.blob());
            fetch_blob.then(blob =>{
                var file = new File([blob], "my_image.png", { type: "image/png", lastModified: new Date().getTime() });
                setImgNoti(notiContext.photo)
                setFileImg(file)
                setTitle(notiContext.title)
                setContent(notiContext.content)
                setId(notiContext._id)
            })
        } else {
            setImgNoti(upImg)
            setTitle('')
            setContent('')
        }
    }, [notiContext, type])

    const handleOk = () => {
        setIsModalCreateNotifyVisible(false);
    };

    const handleCancel = () => {
        setIsModalCreateNotifyVisible(false);
        if (type == "edit") {
            let blob = fetch(notiContext.photo).then(r => r.blob());
            var file = new File([blob], "my_image.png", { type: "image/png", lastModified: new Date().getTime() });
            setImgNoti(notiContext.photo)
            setFileImg(file)
            setTitle(notiContext.title)
            setContent(notiContext.content)
            setId(notiContext._id)
        } else {
            setImgNoti(upImg)
            setTitle('')
            setContent('')
        }
    };
    // handle up img 
    const handleUpImg = () => {
        const [file] = document.querySelector('#inputFile').files
        console.log(Math.random());
        if (file) {
            setFileImg(file)
            document.querySelector('#imgFile').src = URL.createObjectURL(file)
        }
    }
    // handle submit
    const handleSubmit = () => {
        if (type == 'edit') {
            let formdata = new FormData();
            formdata.append("title", title);
            formdata.append("content", content);
            formdata.append("photo", fileImg);
            console.log(fileImg);

            putNotifications(formdata, id)
                .then(() => {
                    Notiflix.Notify.success('Chỉnh sửa thông báo thành công')
                    setIsModalCreateNotifyVisible(false);
                    getlist()
                })
                .catch(() => {
                    Notiflix.Notify.failure('Chỉnh sửa thông báo thất bại')
                })
        } else {
            let formdata = new FormData();
            formdata.append("title", title);
            formdata.append("content", content);
            formdata.append("photo", fileImg);

            if (title && content && fileImg) {
                postNotifications(formdata)
                    .then(() => {
                        Notiflix.Notify.success('Tạo thông báo thành công')
                        setIsModalCreateNotifyVisible(false);
                        getlist()
                    })
                    .catch((err) => {
                        Notiflix.Notify.failure('Tạo thông báo thất bại')
                    })
            } else {
                Notiflix.Notify.warning('Vui lòng nhập đầy đủ thông tin')
            }

        }
    }

    return (
        <Modal
            title={type == 'create' ? 'Tạo thông báo mới' : 'Chỉnh sửa thông tin'}
            visible={isModalCreateNotifyVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
            width={490}
        >
            <FormWrapper>
                <BtnImg>
                    <Img id="imgFile" onClick={() => document.getElementById('inputFile').click()} src={imgNoti} />
                    <input id="inputFile" type="file" onChange={handleUpImg} hidden />
                </BtnImg>
                <BoxTitle className="mb-[15px]">
                    <Title>Tiêu đề</Title>
                    <Input
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </BoxTitle>
                <BoxTitle>
                    <Title>Nội dung thông báo</Title>
                    <Textarea
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                    />
                </BoxTitle>
                <Note>*Vui lòng kiểm tra kĩ nội dung thông báo
                    trước khi nhấn xác nhận.</Note>
                <BoxButton>
                    <Button onClick={handleSubmit}>Xác Nhận</Button>
                </BoxButton>
            </FormWrapper>
        </Modal>
    )
}

export default ModalCreateNotify