import React from 'react'
import Header from './Header'
import Body from './Body'

function Home() {
  return (
    <div>
        <Header />
        <Body />
    </div>
  )
}

export default Home