import React, { useContext, useState, useEffect } from "react";
import { useRoutes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserInformation } from "../store/auth/authThunk";
import ProtectedRoutes from "./ProtectedRoutes";
import PublicRoutes from "./PublicRoutes";
import { getToken, setToken } from "../utils/localStorage";
import { Loading } from "notiflix";
import { MedalContext } from "../context/Context";
import { auth } from '../firebase/Firebase';
import { setRole } from "../store/role/role";
import { getInfoShop } from "../services/authServices";
import { queryShop } from "../store/product/productSlice";

// window.addEventListener('load', function () {
//   initApp();
// });

const AppRoutes = () => {
  const { render } = useContext(MedalContext)
  const [loading, setLoading] = useState(false);
  const [isload, setIsload] = useState(true);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  // const initApp = function () {
  //   auth.onAuthStateChanged(function (user) {
  //     if (user) {
  //       user.getIdToken(true)
  //         .then(function (idToken) {
  //           setToken(idToken);
  //           setIsload(!isload);
  //         }).catch(function (error) {

  //         });
  //     } else {
  //       // location.href = '/users/login?signInSuccessUrl=' + encodeURIComponent(window.location.pathname);
  //     }
  //   }, function (error) {
  //     console.log(error);
  //   });
  // };

  useEffect(() => {
    setInterval(() => {
      auth.onAuthStateChanged(function (user) {
        if (user) {
          user.getIdToken(true)
            .then(function (idToken) {
              console.log(idToken);
              setToken(idToken);
              setIsload(!isload);
            }).catch(function (error) {

            });
        } else {
          // location.href = '/users/login?signInSuccessUrl=' + encodeURIComponent(window.location.pathname);
        }
      })
    }, 1200000);
  }, [])

  const infoSuperAdmin = user?.role.some((item, i) => {
    return item == 'SUPPERADMIN'
  })

  const infoAdmin = user?.role.some((item, i) => {
    return item == 'ADMIN'
  })

  const infoShop = user?.role.some((item, i) => {
    return item == 'SHOP'
  })

  if (infoSuperAdmin) {
    dispatch(setRole('SUPERADMIN'))
  }
  else if (infoAdmin) {
    dispatch(setRole('ADMIN'))
  } else {
    dispatch(setRole('SHOP'))
    getInfoShop()
      .then(res => {
        dispatch(queryShop(res.data._id))
      })
  }

  const routes = user && user != {} && infoAdmin || infoShop ? ProtectedRoutes : PublicRoutes;

  const element = useRoutes([...routes]);

  // set isloading khi load đươc thông tin user
  // useEffect(() => {
  //   if (loading) Loading.pulse();
  //   else Loading.remove();
  // }, [loading]);

  useEffect(() => {
    const jwtToken = getToken();
    if (jwtToken) {
      setLoading(true);
      dispatch(getUserInformation({ setLoading }));
    }
  }, [render]);

  return !loading && <React.Fragment>{element}</React.Fragment>;
};

export default AppRoutes;
