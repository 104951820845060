import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    renderValue: 0,
}

export const renderSlice = createSlice({
    name: 'renderSlice',
    initialState,
    reducers:{
        render: (state, action) => {
            state.renderValue = action.payload;
        }
    }
})

export const { render } = renderSlice.actions;
export default renderSlice.reducer;