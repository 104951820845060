import AxiosClient from "./axiosClient";

const API_ENDPOINT = "/environment/operating";

export const GetInfoTimeOpen = () => {
    return AxiosClient.get(API_ENDPOINT);
};

export const PutTimeOpen = (data) => {
    return AxiosClient.put(API_ENDPOINT, data);
};

export const ChangeSttTimeOpen = (data) => {
    return AxiosClient.put('/environment/openShop', data);
};