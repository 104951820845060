import React from 'react'
import orderDelivery from '../../../assets/icon/orderDelivery.png';

function ListBoxStatistics({ report, type }) {
    var formatter = new Intl.NumberFormat('vi', {
        style: 'currency',
        currency: 'VND',
    });
    return (
        <div className="flex my-[20px]">
            <div className="mr-[19px] rounded-[7px] w-[20%] flex items-start border-[2px] border-primary py-[5px] px-[10px]">
                <div className="mr-[15px]">
                    <img className="w-[20px]" src={orderDelivery} />
                </div>
                <div>
                    <div className="text-[15px] font-semibold text-[#919191] mb-[4px]">{type ? type :`Tổng Đơn`}</div>
                    <div className="text-[17px] font-bold ">{report?.totalOrder} Suất</div>
                </div>
            </div>
            <div className="mr-[19px] rounded-[7px] w-[20%] flex items-start border-[2px] border-primary py-[5px] px-[10px]">
                <div className="mr-[15px]">
                    <img className="w-[20px]" src={orderDelivery} />
                </div>
                <div>
                    <div className="text-[15px] font-semibold text-[#919191] mb-[7px]">Tổng COD</div>
                    <div className="text-[17px] font-bold ">0</div>
                </div>
            </div>
            <div className="mr-[19px] rounded-[7px] w-[20%] flex items-start border-[2px] border-primary py-[5px] px-[10px]">
                <div className="mr-[15px]">
                    <img className="w-[20px]" src={orderDelivery} />
                </div>
                <div>
                    <div className="text-[15px] font-semibold text-[#919191] mb-[7px]">Tổng Ship</div>
                    <div className="text-[17px] font-bold ">{formatter.format(report?.totalShip)}</div>
                </div>
            </div>
            <div className="mr-[19px] rounded-[7px] w-[20%] flex items-start border-[2px] border-primary py-[5px] px-[10px]">
                <div className="mr-[15px]">
                    <img className="w-[20px]" src={orderDelivery} />
                </div>
                <div>
                    <div className="text-[15px] font-semibold text-[#919191] mb-[7px]">Tổng tiền hàng</div>
                    <div className="text-[17px] font-bold ">{formatter.format(report?.totalPrice)}</div>
                </div>
            </div>
            <div className="rounded-[7px] w-[20%] flex items-start border-[2px] border-primary py-[5px] px-[10px]">
                <div className="mr-[15px]">
                    <img className="w-[20px]" src={orderDelivery} />
                </div>
                <div>
                    <div className="text-[15px] font-semibold text-[#919191] mb-[7px]">Tổng tiền thành công</div>
                    <div className="text-[17px] font-bold ">{formatter.format(report?.totalComplete)}</div>
                </div>
            </div>
        </div>
    )
}

export default ListBoxStatistics