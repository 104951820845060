import React, { useContext, useState, useEffect } from 'react';
import { getListShop, changeSttShop, getListShopArea } from '../../../services/shopServices'
import Dropdown from 'react-bootstrap/Dropdown';
import { MedalContext } from '../../../context/Context'
import ModalEditShop from '../../../components/UI/Modals/ModalEditShop'
import { Table } from 'antd';
import lock from '../../../assets/icon/lock.png';
import unLock from '../../../assets/icon/unLock.png';
import lockGreen from '../../../assets/icon/lockGreen.png';
import downIcon from '../../../assets/icon/downIcon.png';
import searchIcon from '../../../assets/icon/searchIcon.png'
import dropdown from '../../../assets/icon/dropDown.png'
import editIcon from '../../../assets/icon/editIcon.png'
import './styles.scss';
import { Input, InputForm, ImgForm } from './TabUser.styles'
import { useSelector } from 'react-redux';
import { set } from 'date-fns';

function TabShop() {
  // Redux
  const user = useSelector((state) => state.auth.user)
  const codeArea = useSelector((state) => state.area.codeArea)
  const role = useSelector((state) => state.role.role)

  // Context 
  const { setIsModalEditMangerVisible, reRender } = useContext(MedalContext);

  const [params, setsParams] = useState({
    codeArea: role == "SUPERADMIN" ? codeArea : user.codeArea,
    page: '1',
    limit: '15',
    oderBy: 'createdAt'
  })
  const [Shop, setShop] = useState([])
  const [panage, setPanage] = useState(1)
  const [countPage, setCountPage] = useState()
  const [fillterNum, setFillterNum] = useState('')
  const [idShop, setIdShop] = useState()
  const [totalShop, setTotalShop] = useState('')

  // handle update shop  
  const handleUpdateShop = (id) => {
    setIdShop(id)
    setIsModalEditMangerVisible(true)
  }

  // handle left btn pange
  const handleLeft = () => {
    if (panage <= 1) {
      setPanage(1)
    } else {
      setPanage(prev => prev - 1)
      setsParams({ ...params, page: panage - 1 })
    }
  }
  // handle change status shop 
  const handleChangeStatus = async (text, id) => {
    if (text === 'ACTIVE') {
      await changeSttShop(id, 'WAITING')
      await getlist()
    } else {
      await changeSttShop(id, 'ACTIVE')
      await getlist()
    }
  }
  // handle fillter
  const handleFilter = () => {
    setsParams({
      ...params,
      query: fillterNum,
      page: 1
    })
  }
  // handle right btn pange
  const handleRight = () => {
    if (panage >= countPage) {
      return
    } else {
      setPanage(prev => prev + 1)
      setsParams({ ...params, page: panage + 1 })
    }
  }
  // get list shop
  const getlist = async () => {
    await getListShopArea(params)
      .then((shops) => {
        setTotalShop(shops.data.totalElement);
        setShop(shops.data.data);
        setCountPage(shops.data.countPage);
      })
      .catch((err) => {
        console.log(err);
      })
  }
  useEffect(() => {
    getlist()
  }, [panage, params, reRender])

  useEffect(() => {
    setsParams({ ...params, codeArea: role == 'SUPERADMIN' ? codeArea : user.codeArea })
  }, [codeArea])

  // table shop
  const data = [];

  Shop.map((shop, index) => (
    data.push({
      key: index,
      phone: shop?.user?.phone,
      name: shop?.name,
      manager: shop?.user?.fullName,
      address: shop?.address?.address,
      gmail: shop?.user?.email,
      status: [shop?.status, shop?._id],
      more: shop?._id
    })
  ))

  const columns = [
    {
      title: 'Sđt',
      dataIndex: 'phone',
    },
    {
      title: 'Tên shop',
      dataIndex: 'name',
    },
    {
      title: 'Quản lý',
      dataIndex: 'manager',
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'address',
    },
    {
      title: 'Gmail',
      dataIndex: 'gmail',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: ([text, id]) => (
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            <div className="w-[150px] flex justify-between items-center px-[10px] py-[5px] border-[1px] border-[#ccc] rounded-[3px]">
              <span className={`font-bold ${text == 'ACTIVE' ? 'text-[#00E974]' : 'text-[#FF1B1B]'} `}>{text == 'ACTIVE' ? 'Đang mở' : 'Đang khoá'}</span>
              <img className="w-[17px] h-[24px] mx-[10px]" src={text == 'ACTIVE' ? lockGreen : lock} />
              <img className="w-[15px] h-[9px]" src={downIcon} />
            </div>
          </Dropdown.Toggle>
          <div className="drop-status">
            <Dropdown.Menu>
              <Dropdown.Item>
                <div
                  onClick={() => handleChangeStatus(text, id)}
                  className={`${text == 'ACTIVE' ? ' bg-[#FF1B1B]' : ' bg-[#00E974]'} text-white flex items-center justify-between font-bol px-[10px] py-[5px] py-[5px rounded-[3px]`}
                >
                  {text == 'ACTIVE' ? 'Khoá' : 'Mở'}
                  <img className="w-[17px] h-[24px]" src={unLock} />
                </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </div>
        </Dropdown>
      )
    },
    {
      title: '',
      dataIndex: 'more',
      width: '5%',
      render: (id) => (
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            <img className="w-[25px] h-[25px]" src={dropdown} />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item>
              <div className="flex items-center justify-between px-[10px]" onClick={() => handleUpdateShop(id)}>
                <img className="w-[20px] h-[20px]" src={editIcon} />
                <span>Chỉnh sửa</span>
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )
    },
  ];

  return (
    <div className="manager-pane flex-1 flex flex-col overflow-y-scroll">
      <div className='flex flex-col h-full'>
        <InputForm>
          <Input
            placeholder="Nhập số điện thoại"
            onChange={(e) => setFillterNum(e.target.value)}
            value={fillterNum}
          />
          <ImgForm onClick={handleFilter}>
            <img className="w-[16px]" src={searchIcon} />
          </ImgForm>
        </InputForm>
        <div className='flex-1 h-full overflow-y-scroll'>
          <Table pagination={{ pageSize: 15 }} columns={columns} dataSource={data} />
        </div>
        <div className="w-full flex justify-center relative px-[20px] py-[20px]">
          <div className='flex gap-[5px] absolute left-[20px] text-[17px] top-[50%] translate-y-[-50%]'>
            <span className='text-[#555555]'>Tổng Shop:</span>
            <span className='font-bold'>{data.length}</span>
          </div>
          <div className="w-fit flex text-center border-2 border-[#ccc]">
            <div
              className="text-[23px] font-bold bg-[#e0e0e0] px-[10px] text-primary hover:cursor-pointer"
              onClick={handleLeft}
            >
              &#x3c;
            </div>
            <div className="text-[23px] font-bold mx-[40px]">
              {countPage ? `${panage}/${countPage}` : panage}
            </div>
            <div
              className="text-[23px] font-bold bg-[#e0e0e0] px-[10px] text-primary hover:cursor-pointer"
              onClick={handleRight}
            >
              &#x3e;
            </div>
          </div>
        </div>
        <ModalEditShop id={idShop} />
      </div>
    </div>
  )
}

export default TabShop