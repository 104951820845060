import AxiosClient from "./axiosClient";

const API_ENDPOINT = "/req-create-shop";

export const getListRQuser = (params) => {
  return AxiosClient.get(API_ENDPOINT,{ params: params});
};
export const getNumRQuser = (status) => {
  return AxiosClient.get(API_ENDPOINT+`/${status}`);
};
export const changeSttRQShop = (id,data) => {
  return AxiosClient.put(API_ENDPOINT+`/${id}`,data);
};