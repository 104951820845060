import React, { useEffect, useContext, useState, useRef } from "react";
import Navbar from "./Navbar";
import {
    Input, ImgForm, InputForm, FormWrapper,
    TitileHeading, BtnFileExcel, FormInfoUser, TextBtn,
    FormFilter, DropdownINput, DropdownINputTxt, FormControl,
    DropdownList, DropdownItem, CreateOrder, RF
} from "./Header.styles";
import searchIcon from "../../../assets/icon/searchIcon.png";
import userIcon from "../../../assets/icon/userIcon.png";
import downIcon from "../../../assets/icon/downIcon.png";
import checked from "../../../assets/icon/checked.png";
import check from "../../../assets/icon/check.png";
import sheets from "../../../assets/icon/sheets.png";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { getUserInformation } from "../../../store/auth/authThunk";
import { MedalContext } from "../../../context/Context";
import rightIconbg from "../../../assets/icon/rightIconbg.png";
import { removeToken } from "../../../utils/localStorage";
import { authAction } from "../../../store/auth/authSlice";
import { BsChevronDown } from "react-icons/bs";
import { getAuth, signOut } from "firebase/auth";
import DatePickerReport from "../../../components/UI/DatePicker/DatePickerReport";
import CustomModal from "./CustomModal/CustomModal";
import dateFilter from "../../../assets/icon/dateFilter.png";
import { BsArrowRight } from "react-icons/bs";
import { AiOutlineReload } from "react-icons/ai";
import { BsFillCaretDownFill } from "react-icons/bs";
import ReportFilterDotIcon from "../../../assets/icons/ReportFilterDotIcon.png";
import createOderIcon from "../../../assets/icons/createOderIcon.png";
import { format, set } from "date-fns";
import { createOrder, getProduct, getSize, getTopping } from "../../../services/productServices";
import {
    addCart, addCart2, clearnData, clearnOder, minusPro,
    plusPro, removeCart, removeToppingPro, searchProduct,
    selectedProduct, setSizePro, setToppingPro
} from "../../../store/product/productSlice";
import { setCodeArea } from "../../../store/area/area";
import Notiflix from "notiflix";
import {
    getCart, orderProduct, totalMoney, totalOrderprice,
} from "../../../store/product/productSelector";
import { getInfoShop, logoutUser } from "../../../services/authServices";
import { getMessaging, getToken } from "firebase/messaging";
import { getTimeLockOder } from "../../../services/environment";
import { getListArea } from "../../../services/areaServices";
import Select from "react-select";
import { data } from "autoprefixer";

function Header() {
    const dispatch = useDispatch();
    const { setRender, setStatus, status } = useContext(MedalContext);
    const user = useSelector((state) => state.auth.user);
    const [searchValue, setSearchValue] = useState("");
    const [valueFil, setValueFil] = useState("Tất cả đơn");
    const [showDrop, setShowDrop] = useState(false);
    const [isShowTimePicker, setIsShowTimePicker] = useState(false);
    const [startTime, setStartTime] = useState(false);
    const [endTime, setEndTime] = useState(false);
    const [listProduct, setListProduct] = useState([]);
    const [size, setSize] = useState();
    const [topping, setTopping] = useState();
    const [from, setFrom] = useState("");
    const [OpenTime, setOpenTime] = useState("");
    const [closeTime, setCloseTime] = useState("");
    const [nowTime, setNowTime] = useState("");
    const [listArea, setListArea] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');

    const role = useSelector((state) => state.role.role);

    const paramProduct = useSelector((state) => state.product.status);
    const selectedPro = useSelector((state) => state.product.selected.pro);
    const selectedProCount = useSelector((state) => state.product.selected?.count);
    const sizeSelected = useSelector((state) => state.product.size);
    const toppingSelected = useSelector((state) => state.product.topping);
    const messaging = getMessaging();

    // handle lock oder app
    // const handleLockOder = () => {
    //     getTimeLockOder()
    //         .then(res => {
    //             console.log(res);
    //             setNowTime(format(new Date(res.data.timeNow), 'hh:mm'))
    //             setOpenTime(res.data.timeOpen)
    //             setCloseTime(res.data.timeClose)
    //         })
    //         .catch(err => {
    //             console.log(err);
    //         })
    // }

    const getTimeCloseApp = async () => {
        const res = await getTimeLockOder();
        // console.log('Time Close Order: ', res);

        //check tính năng setup thời gian đóng mở App có đc kích hoạt không [isOpen], nếu true thì xử lý tiếp
        if (res?.data.isOpen) {
            // console.log(res?.data?.timeOpen?.split(':'));
            const [hourOpen, minuteOpen] = res.data.timeOpen.split(":").map(Number);
            const [hourClose, minuteClose] = res.data.timeClose.split(":").map(Number);

            const timeNowRes = new Date(res.data.timeNow);
            const [hourTimeNow, minuteTimeNow] = [
                Number(timeNowRes.getHours()),
                Number(timeNowRes.getMinutes()),
            ];

            if (hourTimeNow > hourOpen && hourTimeNow < hourClose) {
                // thời gian hiện tại nằm trong khung giờ mở cửa, app không đóng
                // console.log(typeof hourTimeNow);
                return false;
            } else {
                if (hourTimeNow == hourOpen || hourTimeNow == hourClose) {
                    if (hourOpen == hourClose) {
                        if (minuteTimeNow >= minuteOpen && minuteTimeNow <= minuteClose) {
                            return false;
                        } else {
                            return true;
                        }
                    } else {
                        if (
                            (hourTimeNow == hourOpen && minuteTimeNow >= minuteOpen) ||
                            (hourTimeNow == hourClose && minuteTimeNow <= minuteClose)
                        ) {
                            return false;
                        } else {
                            return true;
                        }
                    }
                } else {
                    // thời gian hiện tại nằm ngoài thời gian đặt hàng
                    return true;
                }
            }
        } else {
            //Chức năng đóng mở App không được bật
            return false;
        }
    };

    const getArea = async () => {
        const res = await getListArea()
        return setListArea(res.data)
    }

    const dataOption = [{ value: "", label: "Tất cả khu vực" }];

    listArea.map((item) => {
        dataOption.push({
            value: item.codeArea,
            label: item.nameArea,
        })
    })

    const listFil = [
        {
            text: "Tất cả đơn",
            type: "",
        },
        {
            text: "Đơn khách tạo",
            type: "USER",
        },
        {
            text: "Đơn shop tạo",
            type: "SHOP",
        },
        {
            text: "Đơn giao hộ",
            type: "SHIP",
        },
    ];

    // handle filter by type oder
    const filterByTypeOder = (item) => {
        setValueFil(item.text);
        setStatus({ ...status, type: item.type, page: 1 });
    };

    useEffect(() => {
        dispatch(getUserInformation());
    }, []);

    const auth = getAuth();
    const onClickLogout = () => {
        Notiflix.Loading.pulse();
        auth
            .signOut()
            .then(() => {
                // removeToken();
                // setRender(Math.random())
                // dispatch(authAction.logout());
                getToken(messaging, {
                    vapidKey:
                        "BLqSs5cqFv2IpNgchCfWULuMR7rOlIjzFjSBBpLccKjcQ-b7DuEjS-6HyVx2ZW7ZORFytDzr0w-hLeBJP4GDRHA",
                })
                    .then((result) => {
                        logoutUser({ fcmToken: result })
                            .then((res) => {
                                console.log("logged out");
                                Notiflix.Loading.remove();
                                removeToken();
                                dispatch(authAction.logout());
                            })
                            .catch((err) => {
                                Notiflix.Loading.remove();
                                console.log(err);
                            });
                    })
                    .catch((err) => {
                        removeToken();
                        dispatch(authAction.logout());
                        Notiflix.Loading.remove();
                    });
                // window.location.reload();
            })
            .catch((error) => {
                // An error happened.
            });
        getInfoShop({
            fcmToken: "",
        });
    };
    useEffect(() => {
        const handleWindow = () => {
            setShowDrop(false);
        };
        window.addEventListener("click", handleWindow);

        return () => {
            window.removeEventListener("click", handleWindow);
        };
    }, []);
    // handle close timePicker filter
    if (isShowTimePicker) {
        document.addEventListener("click", () => {
            setIsShowTimePicker(false);
        });
    }

    // handle modal create oder
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // handle product
    const [nameProduct, setnameProduct] = useState("");
    const [countPro, setCountPro] = useState(0);
    // hàm search
    const handleSearch = () => {
        Notiflix.Loading.pulse();
        if (paramProduct.queryShop) {
            getProduct(paramProduct)
                .then((res) => {
                    setListProduct(res.data.data);
                    Notiflix.Loading.remove();
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        Notiflix.Loading.remove();
    };
    useEffect(() => {
        handleSearch();
    }, [paramProduct]);
    // handle search
    const handleSearchProduct = (e) => {
        setnameProduct(e.target.value);
        dispatch(searchProduct(e.target.value));
    };
    // hanlde selected product
    const handleselectedProduct = (product) => {
        dispatch(selectedProduct(product));
    };
    // console.log(selectedPro);
    // handle get size
    const handleSize = () => {
        Notiflix.Loading.pulse();
        getSize({ id: selectedPro._id })
            .then((res) => {
                setSize(res.data);
                dispatch(setSizePro(res.data[0]));
                Notiflix.Loading.remove();
            })
            .catch((err) => {
                Notiflix.Loading.remove();
            });
        getTopping({ id: selectedPro._id })
            .then((res) => {
                setTopping(res.data);
                Notiflix.Loading.remove();
            })
            .catch((err) => {
                Notiflix.Loading.remove();
            });
    };
    useEffect(() => {
        if (selectedPro) {
            handleSize();
        }
    }, [selectedPro]);

    // get count topping
    const handleCountTop = (id) => {
        return toppingSelected.findIndex((item) => {
            return id == item.item._id;
        });
    };
    // get info shop
    useEffect(() => {
        getArea()
        getInfoShop().then((res) => {
            setFrom(res.data.address.address);
        });
    }, []);

    const temporaryMoney = useSelector(totalMoney);
    const ordercart = useSelector(orderProduct);
    const getcart = useSelector(getCart);
    const getTotalMoney = useSelector(totalOrderprice);

    // console.log(getcart);
    const handleTopping = (item) => {
        const topping = item.map((item) => ({
            id: item.item._id,
            amount: item.count,
        }));
        return topping;
    };

    const onSubmit = async (data) => {
        console.log(data);
        //   Notiflix.Loading.pulse();
        //   const detail = getcart.map((item) => {
        //      return {
        //         product: item.product.pro._id,
        //         topping: handleTopping(item.topping),
        //         amount: item.product.count,
        //         size: item.size?._id,
        //      };
        //   });
        //   const isClose = await getTimeCloseApp();
        //   if (!isClose) {
        //      createOrder({
        //         details: detail,
        //         note: data.note,
        //         fullName: data.name,
        //         phone: data.phone,
        //         from: data.address,
        //         to: from,
        //         distance: 0,
        //         key: "string",
        //      })
        //         .then((res) => {
        //            console.log(res);
        //            dispatch(clearnData());
        //            Notiflix.Loading.remove();
        //            Notiflix.Notify.success("Tạo đơn thành công");
        //            handleClose();
        //            setRender(Math.random());
        //         })
        //         .catch((err) => {
        //            console.log(err);
        //            Notiflix.Loading.remove();
        //            switch (err.response.data.code) {
        //               case "STATUS_INVALID":
        //                  Notiflix.Notify.failure("Lỗi sản phẩm không tồn tại");
        //                  break;
        //               case "NOT_IN_ONE_SHOP":
        //                  Notiflix.Notify.failure("Lỗi sản phẩm không thuộc một shop");
        //                  break;
        //               case "TOPPING_INVALID":
        //                  Notiflix.Notify.failure("Lỗi Topping không thuộc sản phẩm");
        //                  break;
        //               default:
        //                  break;
        //            }
        //         });
        //   } else {
        //      Notiflix.Loading.remove();
        //      Notiflix.Notify.failure("Đặt hàng ngoài thời gian giao hàng");
        //   }
    };

    return (
        <div className="shadow-[0_2px_30px_-2px_rgba(0,0,0,0.3)]">
            <FormWrapper>
                <div>
                    <TitileHeading>Quản lý đơn hàng</TitileHeading>
                    <FormControl>
                        {(role == "ADMIN" || role == 'SUPERADMIN') && (
                            <FormFilter>
                                <DropdownINput
                                    onClick={(e) => {
                                        setShowDrop(true);
                                        e.stopPropagation();
                                    }}
                                >
                                    <DropdownINputTxt>{valueFil}</DropdownINputTxt>
                                    <BsChevronDown className="translate-y-[25%] text-[17px]" />
                                </DropdownINput>
                                {showDrop && (
                                    <DropdownList>
                                        {listFil.map((item, i) => (
                                            <DropdownItem
                                                key={i}
                                                onClick={(e) => {
                                                    filterByTypeOder(item);
                                                }}
                                            >
                                                {" "}
                                                {item.text}{" "}
                                            </DropdownItem>
                                        ))}
                                    </DropdownList>
                                )}
                            </FormFilter>
                        )}
                        <div className="relative">
                            <div
                                className="h-full flex justify-center items-center mr-[25px] border-[1px] border-[#cccccc] rounded-[7px] px-[15px] py-[5px] bg-white"
                                onClick={(e) => {
                                    setIsShowTimePicker(true);
                                    e.stopPropagation();
                                }}
                            >
                                <img className="w-[25px] h-[25px] mr-[10px]" src={dateFilter} />
                                {startTime && endTime ? (
                                    <>
                                        <span className="text-[17px] mr-[10px]">
                                            {format(new Date(startTime), "yyyy-MM-dd")}
                                        </span>
                                        <span className="text-[20px] mr-[10px]">
                                            <BsArrowRight />
                                        </span>
                                        <span className="text-[17px]">
                                            {format(new Date(endTime), "yyyy-MM-dd")}
                                        </span>
                                    </>
                                ) : (
                                    <span className="text-[17px]">Hôm nay</span>
                                )}
                                <BsFillCaretDownFill className="text-[17px] ml-[10px]" />
                            </div>

                            {isShowTimePicker && (
                                <RF.formPickerTime onClick={(e) => e.stopPropagation()}>
                                    <RF.FormControl>
                                        <RF.Label>
                                            <img
                                                className="w-[30px]"
                                                src={ReportFilterDotIcon}
                                                alt=""
                                            />
                                            Từ ngày
                                        </RF.Label>
                                        <DatePickerReport
                                            selected={startTime}
                                            onChange={(date) => setStartTime(date)}
                                        />
                                    </RF.FormControl>
                                    <RF.FormControl>
                                        <RF.Label>
                                            <img
                                                className="w-[30px]"
                                                src={ReportFilterDotIcon}
                                                alt=""
                                            />
                                            Tới ngày
                                        </RF.Label>
                                        <DatePickerReport
                                            selected={endTime}
                                            onChange={(date) => setEndTime(date)}
                                        />
                                    </RF.FormControl>
                                    <RF.FormControl className="mt-[37px]">
                                        <RF.Button
                                            onClick={() => {
                                                setStatus({
                                                    ...status,
                                                    startDate: format(
                                                        new Date(startTime),
                                                        "yyyy-MM-dd"
                                                    ),
                                                    endDate: format(new Date(endTime), "yyyy-MM-dd"),
                                                });
                                                setIsShowTimePicker(false);
                                            }}
                                        >
                                            Xác nhận
                                        </RF.Button>
                                        <RF.BtnToday
                                            className="flex items-center justify-around"
                                            onClick={() => {
                                                setStatus({
                                                    ...status,
                                                    startDate: format(new Date(), "yyyy-MM-dd"),
                                                    endDate: format(new Date(), "yyyy-MM-dd"),
                                                });
                                                setIsShowTimePicker(false);
                                                setStartTime(false);
                                                setEndTime(false);
                                            }}
                                        >
                                            <AiOutlineReload className="text-[16px]" />
                                            Hôm nay
                                        </RF.BtnToday>
                                    </RF.FormControl>
                                </RF.formPickerTime>
                            )}
                        </div>
                        <InputForm>
                            <Input
                                placeholder="Nhập mã đơn hàng"
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                            />
                            <ImgForm
                                onClick={() =>
                                    setStatus({ ...status, query: searchValue, page: 1 })
                                }
                            >
                                <img className="w-[16px]" src={searchIcon} />
                            </ImgForm>
                        </InputForm>
                        {/* modal create oder shop */}
                        <CustomModal
                            show={show}
                            handleClose={handleClose}
                        />
                        {/* btn create oder of shop */}
                        {role == "SHOP" ? (
                            <div
                                className="flex gap-[15px] items-center ml-[20px] px-[15px] py-[10px] border-solid border-[1px] border-[#FF1393] rounded-lg cursor-pointer"
                                onClick={handleShow}
                            >
                                <img className="w-[27px]" src={createOderIcon} />
                                <span className="text-[17px] text-[#FF1393]">Tạo đơn ngay</span>
                            </div>
                        ) : <div
                            className="flex gap-[15px] items-center ml-[20px] px-[15px] py-[10px] border-solid border-[1px] border-[#FF1393] rounded-lg cursor-pointer"
                            onClick={handleShow}
                        >
                            <img className="w-[27px]" src={createOderIcon} />
                            <span className="text-[17px] text-[#FF1393]">Tạo đơn ngay</span>
                        </div>
                        }
                        {role == 'SUPERADMIN' && (
                            <Select
                                value={selectedOption}
                                options={dataOption}
                                defaultValue={selectedOption}
                                placeholder="Chọn khu vực"
                                onChange={(e) => {
                                    setSelectedOption(e);
                                    dispatch(setCodeArea(e.value));
                                }}
                                classNames="flex items-center ml-[20px] max-w-1"
                                className="flex items-center ml-[20px] max-w-1"
                                isSearchable={false}
                            />
                        )}
                    </FormControl>
                </div>
                <div>
                    <FormInfoUser>
                        <Dropdown>
                            <Dropdown.Toggle>
                                <div className="flex items-center">
                                    <span className="text-[15px] ">{user.phone}</span>
                                    <img className="w-[35px] h-[35px] mx-[10px]" src={userIcon} />
                                    <img className="w-[15px] h-[7px]" src={downIcon} />
                                </div>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item>
                                    <div
                                        onClick={onClickLogout}
                                        className="flex justify-between items-center"
                                    >
                                        <div>Đăng xuất </div>
                                        <img className="w-[30px]" src={rightIconbg} />
                                    </div>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </FormInfoUser>
                </div>
            </FormWrapper>
            <Navbar />
        </div>
    );
}

export default Header;
