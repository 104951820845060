import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selected: {
        pro: null,
        count: 1
    },
    size: null,
    topping: [],
    status: {
        queryName: '',
        page: 1,
        limit: 50,
        queryShop: ''
    },
    cart: []
}

const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        searchProduct: (state, action) => {
            state.status.queryName = action.payload;
        },
        selectedProduct: (state, action) => {
            state.selected.pro = action.payload;
        },
        queryShop: (state, action) => {
            state.status.queryShop = action.payload
        },
        plusPro: (state, action) => {
            state.selected.count += 1
        },
        minusPro: (state, action) => {
            if (state.selected.count <= 1) {
                state.selected.pro = null
            } else {
                state.selected.count -= 1
            }
        },
        setSizePro: (state, action) => {
            state.size = action.payload;
        },
        setToppingPro: (state, action) => {
            if (state.topping.length !== 0) {
                state.topping.forEach(item => {
                    if (item.item._id === action.payload.item._id) {
                        item.count += 1
                    }
                })
                let check = state.topping.every(item => {
                    return item.item._id !== action.payload.item._id
                })
                if (check) {
                    state.topping.push(action.payload)
                }
            } else {
                state.topping.push(action.payload)
            }
        },
        removeToppingPro: (state, action) => {
            if (state.topping.length !== 0) {
                state.topping.forEach(item => {
                    if (item.item._id === action.payload.item._id) {
                        if (item.count <= 1) {
                            const removeTop = state.topping.filter(item => {
                                return item.item._id !== action.payload.item._id
                            })
                            state.topping = removeTop
                        } else {
                            item.count -= 1
                        }
                    }
                })
            }
        },
        addCart: (state, action) => {
            if (state.cart.length !== 0) {

                const results = state.cart.filter((cartState) => cartState.product.pro._id === action.payload.product.pro._id);
                // console.log(results)
                if (results) {
                    const indexSize = results.findIndex(item => item.size?._id === action.payload.size?._id)
                    const indexTopping = results.findIndex(item => JSON.stringify(item.topping) === JSON.stringify(action.payload.topping))
                    const toppingState = JSON.stringify(results[indexTopping]?.topping)
                    const toppingPayload = JSON.stringify(action.payload.topping)
                    if (results[indexSize]?.size?._id !== action.payload?.size?._id) {
                        state.cart.push(action.payload)
                    } else if (toppingState !== toppingPayload) {
                        state.cart.push(action.payload)
                    } else {
                        const final = results.find(item => item.size?._id == action.payload.size?._id && JSON.stringify(item.topping) == JSON.stringify(action.payload.topping))
                        if (final) {
                            final.product.count += action.payload.product.count
                            final.total = final.total + (((final.product.pro?.price + (final.size ? final.size.price : 0)) * action.payload.product.count))
                        }
                    }
                } else {
                    state.cart.push(action.payload)
                }
            } else {
                state.cart.push(action.payload)
            }
        },
        addCart2: (state, action) => {
            if (state.cart.length !== 0) {

                const results = state.cart.filter((cartState) => cartState.product.pro._id === action.payload.product.pro._id);
                // console.log(results)
                if (results) {
                    const indexSize = results.findIndex(item => item.size?._id === action.payload.size?._id)
                    const indexTopping = results.findIndex(item => JSON.stringify(item.topping) === JSON.stringify(action.payload.topping))
                    const toppingState = JSON.stringify(results[indexTopping]?.topping)
                    const toppingPayload = JSON.stringify(action.payload.topping)
                    if (results[indexSize]?.size?._id !== action.payload?.size?._id) {
                        state.cart.push(action.payload)
                    } else if (toppingState !== toppingPayload) {
                        state.cart.push(action.payload)
                    } else {
                        const final = results.find(item => item.size?._id == action.payload.size?._id && JSON.stringify(item.topping) == JSON.stringify(action.payload.topping))
                        if (final) {
                            final.product.count += 1
                            final.total = final.total + (final.product.pro?.price + (final.size ? final.size.price : 0))
                        }
                    }
                } else {
                    state.cart.push(action.payload)
                }
            } else {
                state.cart.push(action.payload)
            }
        },
        removeCart: (state, action) => {
            console.log(state.cart[action.payload].product.count);
            if (state.cart[action.payload].product.count <= 1) {
                state.cart.splice(action.payload, 1)
            } else {
                state.cart[action.payload].product.count = state.cart[action.payload].product.count - 1
                state.cart[action.payload].total = state.cart[action.payload].total - state.cart[action.payload].product.pro?.price - (state.cart[action.payload].size ? state.cart[action.payload].size.price : 0)
            }
        },
        clearnOder: (state, action) => {
            state.selected = {
                pro: null,
                count: 1
            }
            state.size = null
            state.topping = []
        },
        clearnData: (state, action) => {
            state.selected = {
                pro: null,
                count: 1
            }
            state.size = null
            state.topping = []
            state.cart = []
        }
    }
})

export const { searchProduct, selectedProduct, setSizePro, setToppingPro, removeToppingPro, plusPro, minusPro, queryShop, addCart, addCart2, clearnOder, removeCart, clearnData } = productSlice.actions;
export default productSlice.reducer;