import React, { useState, useEffect, useContext } from "react";
import {
  WrapForm,
  FormOderList,
  Layout,
  NavList,
  NavItem,
} from "./Pane.styles";
import {
  getListOrderByArea,
  getListOrderShopWithStt,
  getListOrderWithStt,
  getOrderByShip,
  getOrderByType,
} from "../../../services/orderServices";
import OderItem from "../../../components/UI/OderItem";
import { MedalContext } from "../../../context/Context";
import Notiflix, { Loading } from "notiflix";
import { useDispatch, useSelector } from "react-redux";
import { render } from "../../../store/Rerender/RerenderSlice";

function Pane() {
  const user = useSelector((state) => state.auth.user);
  const { status, setStatus, renderPage, reRender } = useContext(MedalContext);
  const [oders, setOders] = useState();
  const [panage, setPanage] = useState(1);
  // const [totalPage, setTotalPage] = useState('')
  const [countPage, setCountPage] = useState();
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const onLoad = useSelector((state) => state.render);
  const role = useSelector((state) => state.role.role);
  const selectedCodeArea = useSelector((state) => state.area.codeArea);
  // get list oder
  useEffect(() => {
    if (status) {
      if (role === "SUPERADMIN") {
        // get order super admin
        Notiflix.Loading.pulse();
        getListOrderByArea({ ...status, codeArea: selectedCodeArea })
          .then((oders) => {
            setOders(oders.data.data);
            setCountPage(oders.data.countPage);
            setLoading(false);
            Notiflix.Loading.remove();
          })
          .catch((err) => {
            console.log(err);
            Notiflix.Loading.remove();
          });
      }
      else if (role === "ADMIN") {
        // get order admin
        Notiflix.Loading.pulse();
        getListOrderByArea({ ...status, codeArea: user?.codeArea })
          .then((oders) => {
            console.log('ADMIN: ', oders.data);
            setOders(oders.data.data);
            setCountPage(oders.data.countPage);
            setLoading(false);
            Notiflix.Loading.remove();
          })
          .catch((err) => {
            console.log(err);
            Notiflix.Loading.remove();
          });
      }
      else {
        // get order shop
        Notiflix.Loading.pulse();
        getListOrderShopWithStt({ ...status, codeArea: user?.codeArea })
          .then((oders) => {
            setOders(oders.data.data);
            setCountPage(oders.data.countPage);
            setLoading(false);
            Notiflix.Loading.remove();
          })
          .catch((err) => {
            console.log(err);
            Notiflix.Loading.remove();
          });
      }
    } else {
      return;
    }
  }, [status, reRender, onLoad, panage, selectedCodeArea]);

  // set auto load page
  // useEffect(() => {
  //     setInterval(() => {
  //         dispatch(render(Math.random()))
  //     }, 5000);
  // }, [])

  // handle left btn pange
  const handleLeft = () => {
    if (panage <= 1) {
      setPanage(1);
    } else {
      setPanage((prev) => prev - 1);
      setStatus({ ...status, page: panage - 1 });
    }
  };

  // handle right btn pange
  const handleRight = () => {
    if (panage >= countPage) {
      return;
    } else {
      setPanage((prev) => prev + 1);
      setStatus({ ...status, page: panage + 1 });
    }
  };
  // set page when click prev & next
  useEffect(() => {
  }, [renderPage]);
  // set loading
  useEffect(() => {
    if (loading) Loading.pulse();
    else Loading.remove();
  }, [loading]);
  // get role
  return (
    <WrapForm>
      <Layout>
        <NavList>
          <NavItem className="w-[110px]">Mã đơn</NavItem>
          <NavItem className="w-[100px]">Sản phẩm</NavItem>
          <NavItem className="w-[300px]">Lộ trình</NavItem>
          <NavItem className="w-[200px]">Thanh toán</NavItem>
          <NavItem className="w-[140px]">Ghi chú</NavItem>
          <NavItem className="w-[140px]">Trạng thái</NavItem>
        </NavList>
        <FormOderList>
          {oders?.map((oder, index) => (
            <OderItem key={index} oder={oder} />
          ))}
        </FormOderList>
        <div className="w-full flex justify-center py-[20px] relative">
          <div className="w-fit flex text-center border-2 border-[#ccc]">
            <div
              className="text-[23px] font-bold bg-[#e0e0e0] px-[10px] text-primary hover:cursor-pointer"
              onClick={handleLeft}
            >
              &#x3c;
            </div>
            <div className="text-[23px] font-bold mx-[20px]">
              {countPage ? `${panage}/${countPage}` : panage}
            </div>
            <div
              className="text-[23px] font-bold bg-[#e0e0e0] px-[10px] text-primary hover:cursor-pointer"
              onClick={handleRight}
            >
              &#x3e;
            </div>
          </div>
        </div>
      </Layout>
    </WrapForm>
  );
}

export default Pane;
