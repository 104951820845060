import AxiosClient from "./axiosClient";

const API_ENDPOINT = "environment";

export const getStatusRain = () => {
    return AxiosClient.get(API_ENDPOINT + '/rain');
};
export const changeStatusRain = (data) => {
    return AxiosClient.put(API_ENDPOINT + '/rain', data);
};
export const getDataShip = () => {
    return AxiosClient.get(API_ENDPOINT + '/data')
}
export const changeDataShip = (data) => {
    return AxiosClient.put(API_ENDPOINT + '/data', data)
}

// % ship
export const getpercentShip = () => {
    return AxiosClient.get(API_ENDPOINT + '/shipinfo')
}

export const getTimeLockOder = () => {
    return AxiosClient.get(API_ENDPOINT + '/operating')
}

export const putpercentShip = (data) => {
    return AxiosClient.put(API_ENDPOINT + '/precentship', data)
}