import React, { useContext, useState, useEffect } from 'react';
import Notiflix from 'notiflix';
import { Modal } from 'antd';
import { MedalContext } from '../../../context/Context'
import check from '../../../assets/icon/check.jpg';
import checked from '../../../assets/icon/checked.jpg';
import {getBusinessType} from '../../../services/businessTypeServices'
import {changeSttRQShop} from '../../../services/RQuserServices'

import {
    FormWrapperType,
    FormBtn,
    Button

} from './Modals.styles'
import './Modals.scss';

function ModalType({id}) {

    const { setRender, isModalTypeVisible, setIsModalTypeVisible, setRegetnumRequest } = useContext(MedalContext)
    const [snacks, setSnacks] = useState(true)
    const [types, setTypes] = useState('')
    const [businessType, setBusinessType] = useState()
    console.log(types);
    // handle submit
    const handleSubmit = () => {
        changeSttRQShop(id,{status:'ACCEPT',businessType:businessType ? businessType : types[0]._id})
        .then(() => {
            Notiflix.Notify.success('thay đổi trạng thái thành công');
            setRender(Math.random())
            setRegetnumRequest(Math.random())
        })
        .catch(() => {
            Notiflix.Notify.failure('thay đổi trạng thái thất bại');
        })
        setIsModalTypeVisible(false)
    }
    
    // get business type
    useEffect(() => {
        getBusinessType()
        .then((type) => {
            setTypes(type.data);
        })
    },[])

    // handle checked  
    const handleCheckLeft = () => {
        setSnacks(true)
        setBusinessType(types[0]._id)
    }

    const handleCheckRight = () => {
        setSnacks(false)
        setBusinessType(types[1]._id)
    }

    return (
        <Modal
            title="Chọn loại hình"
            visible={isModalTypeVisible}
            onCancel={()=>setIsModalTypeVisible(false)}
            footer={null}
            width={300}
        >
            <FormWrapperType>
                <div className="flex justify-between">
                    <div className="flex items-center">
                        <img
                            className="w-[17px] h-[17px] hover:cursor-pointer"
                            src={snacks ? checked : check}
                            onClick={handleCheckLeft}
                        />
                        <span className="ml-[10px] font-bold text-[17px]">Ăn vặt</span>
                    </div>
                    <div className="flex items-center">
                        <img
                            className="w-[17px] h-[17px] hover:cursor-pointer"
                            src={!snacks ? checked : check}
                            onClick={handleCheckRight}
                        />
                        <span className="ml-[10px] font-bold text-[17px]">Tạp hoá</span>
                    </div>
                </div>
                <FormBtn className='justify-between'>
                    <div
                        className="hover:cursor-pointer mt-[10px] text-[#676767] text-[17px] font-bold"
                        onClick={()=>setIsModalTypeVisible(false)}
                    >
                        Huỷ
                    </div>
                    <div
                        className="hover:cursor-pointer mt-[10px] text-primary text-[17px] font-bold"
                        onClick={handleSubmit}
                    >
                        Xác nhận
                    </div>
                </FormBtn>
            </FormWrapperType>
        </Modal>
    )
}

export default ModalType