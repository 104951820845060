import React, { useEffect, useContext, useState } from 'react'
import './styles.scss';
import {
    FormToggle, FormWrapper, TitileHeading, FormInfoUser,
    ButtonUser, ButtonShop,
} from "./Header.styles"
import userIcon from '../../../assets/icon/userIcon.png'
import downIcon from '../../../assets/icon/downIcon.png'
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from "react-redux";
import { getUserInformation } from "../../../store/auth/authThunk";
import { MedalContext } from '../../../context/Context'
import rightIconbg from '../../../assets/icon/rightIconbg.png';
import { removeToken } from "../../../utils/localStorage";
import { authAction } from "../../../store/auth/authSlice";
import { getAuth, signOut } from "firebase/auth";
import { getListArea } from "../../../services/areaServices";
import { setCodeArea } from "../../../store/area/area";
import Select from 'react-select'

function Header() {
    const dispatch = useDispatch();
    const { setRender } = useContext(MedalContext)
    const user = useSelector((state) => state.auth.user);
    const [selectedOption, setSelectedOption] = useState('');
    const [listArea, setListArea] = useState([]);

    const dataOption = [{ value: "", label: "Tất cả khu vực" }];
    listArea.map((item) => {
        dataOption.push({
            value: item.codeArea,
            label: item.nameArea,
        })
    })

    const getArea = async () => {
        const res = await getListArea()
        return setListArea(res.data)
    }

    useEffect(() => {
        getArea()
        dispatch(getUserInformation());
    }, [])

    const auth = getAuth();
    const onClickLogout = () => {
        auth.signOut().then(() => {
            // Sign-out successful.
            removeToken();
            console.log('active')
            setRender(Math.random())
            dispatch(authAction.logout());
        }).catch((error) => {
            // An error happened.
        });

    };
    return (
        <div className="shadow-[0_2px_30px_-2px_rgba(0,0,0,0.3)]">
            <FormWrapper>
                <div>
                    <TitileHeading>Xét duyệt yêu cầu</TitileHeading>
                </div>
                <div>
                    <FormInfoUser>
                        <span className="text-[15px] ">{user.phone}</span>
                        <img className="w-[35px] h-[35px] mx-[10px]" src={userIcon} />
                        <Dropdown>
                            <Dropdown.Toggle>
                                <img className="w-[15px] h-[7px]" src={downIcon} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item>
                                    <div onClick={onClickLogout} className="flex justify-between items-center">
                                        <div>Đăng xuất </div>
                                        <img className="w-[30px]" src={rightIconbg} />
                                    </div>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </FormInfoUser>
                </div>
                {user.role == 'SUPERADMIN' && (
                    <div>
                        <Select
                            value={selectedOption}
                            options={dataOption}
                            defaultValue=''
                            placeholder="Tất cả khu vực"
                            onChange={(e) => {
                                setSelectedOption(e);
                                dispatch(setCodeArea(e.value));
                            }}
                            className="flex"
                            isSearchable={false}
                        />
                    </div>
                )}
            </FormWrapper>
        </div>
    )
}

export default Header