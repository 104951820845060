import tw from "tailwind-styled-components";

export const Header = tw.div`
    w-full
    flex
    justify-between
    px-[20px]
    py-[17px]
    bg-[#FF872B]
`
export const HeaderLeft = tw.div`
    flex
    items-center
    hover:cursor-pointer
`
export const BackIcon = tw.img`
    w-[45px]
    h-[45px]
    p-[12px]
    bg-[#FFD0A2]
    rounded-[50px]
`
export const TitleHeading = tw.div`
    text-[17px]
    font-bold
    text-white
    ml-[30px]
`
export const HeaderRight = tw.div`
    bg-white
    flex
    items-center
    p-[10px]
    rounded-[7px]
    hover:cursor-pointer
`
export const PlusIcon = tw.img`
    w-[25px]
    mr-[20px]
`
export const TextBtn = tw.div`
    text-[17px]
`