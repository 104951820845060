import React, { forwardRef } from "react";
import { DPR } from "./DatePickerReport.style";
import DatePickerIcon from "../../../assets/icons/date-picker-icon.svg";
import DatePickerPrevIcon from "../../../assets/icons/date-picker-prev-icon.svg";
import DatePickerNextIcon from "../../../assets/icons/date-picker-next-icon.svg";
import CaretDownIcon from "../../../assets/icons/caret-down-icon.svg";
import ReactDatePicker from "react-datepicker";
import { getDay, getMonth, getYear } from "date-fns";

const DatePickerReport = ({ selected, onChange }) => {
  const configDayClassName = (date) => {
    const now = new Date();
    let className = "";
    className += !(getMonth(date) === getMonth(now)) ? "!text-[#BBBBBB] " : "";
    className += getDay(date) === 0 ? "!text-[#FF4646] " : "";

    return className;
  };

  const DatePickerInput = forwardRef(({ value, onClick }, ref) => (
    <DPR.DatePickerInput
      className="example-custom-input"
      onClick={onClick}
      ref={ref}
    >
      <img className="w-[27px]" src={DatePickerIcon} alt="" />
      <DPR.DatePickerValue>{value}</DPR.DatePickerValue>
      <img src={CaretDownIcon} width={10} alt="" />
    </DPR.DatePickerInput>
  ));
  DatePickerInput.displayName = "DatePickerInput";

  const CustomHeader = ({
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }) => (
    <DPR.DatePickerHeader>
      <DPR.DatePickerHeaderTitle>
        Tháng {getMonth(date) + 1}/{getYear(date)}
      </DPR.DatePickerHeaderTitle>

      <DPR.DatePickerHeaderButtons>
        <DPR.DatePickerHeaderButton
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
        >
          <img src={DatePickerPrevIcon} alt="" />
        </DPR.DatePickerHeaderButton>
        <DPR.DatePickerHeaderButton
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
        >
          <img src={DatePickerNextIcon} alt="" />
        </DPR.DatePickerHeaderButton>
      </DPR.DatePickerHeaderButtons>
    </DPR.DatePickerHeader>
  );
  DatePickerInput.displayName = "DatePickerInput";

  return (
    <ReactDatePicker
      locale="vi"
      selected={selected}
      dateFormat="dd/MM/yyyy"
      onChange={(date) => onChange(date)}
      customInput={<DatePickerInput />}
      dayClassName={configDayClassName}
      renderCustomHeader={CustomHeader}
    />
  );
};

export default DatePickerReport;
