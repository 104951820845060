import tw from 'tailwind-styled-components';

export const FormWrapper = tw.div`
    px-[20px]
    pt-[30px]
`
export const Navlist = tw.ul`
    w-full
    flex
    justify-between
`
export const NavItem = tw.li`
    text-[16px]
    font-bold
    hover:cursor-pointer
`
export const TxtNav = tw.span`
    mr-[10px]
`
export const IconNav = tw.span`
    px-[10px]
    py-[5px]
    border-2
    rounded-default
`