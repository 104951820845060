import { createSelector } from "@reduxjs/toolkit";
import { selectAuthIsLoading } from "../auth/authSelector";

export const selectLoadingFromAll = createSelector(
  [
    selectAuthIsLoading,
  ],
  (
    authLoading,
    knifeTypeIsLoading,
    partIsLoading,
    supplierIsLoading,
    userIsLoading,
    knifeIsLoading,
    cabinetIsLoading
  ) =>
    authLoading ||
    knifeTypeIsLoading ||
    partIsLoading ||
    supplierIsLoading ||
    userIsLoading ||
    knifeIsLoading ||
    cabinetIsLoading
);
