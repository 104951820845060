import React, { useState, useContext, useEffect } from "react";
import rain from "../../../assets/icon/rain.png";
import turnon from "../../../assets/icon/turnon.png";
import turnoff from "../../../assets/icon/turnoff.png";
import editIconWhite from "../../../assets/icon/editIconWhite.png";
import notifyIcon from "../../../assets/icon/notifyIcon.png";
import banner from "../../../assets/icon/banner.png";
import rightIconbg from "../../../assets/icon/rightIconbg.png";
import ModalEditShop from "../../../components/UI/Modals/ModalEditShip";
import { MedalContext } from "../../../context/Context";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import { getStatusRain, changeStatusRain } from "../../../services/environment";

import axios from "axios";

import {
  RainBox,
  RainIcon,
  Title,
  SwichBtn,
  Warraper,
  ShipBox,
  Heading,
  EditIcon,
  Km,
  Span,
  Ship,
  NotifyBox,
  TitleSpan,
  NotifyHeader,
  NotifyIcon,
  RightIcon,
  TimeBox,
  ModalTime,
} from "./Body.styles";
import Notiflix from "notiflix";
import { getToken } from "../../../utils/localStorage";
import {
  ChangeSttTimeOpen,
  GetInfoTimeOpen,
  PutTimeOpen,
} from "../../../services/timeOpenServices";
import { getListArea, uploadBanner } from "../../../services/areaServices";
import { useForm } from "react-hook-form";
import { uploadProduct } from "../../../services/productServices";

function Body() {
  let navigate = useNavigate();
  const [modalSetTime, setModalSetTime] = useState(false);
  const [statusRain, setStatusRain] = useState();
  const [statusTime, setStatusTime] = useState();

  const [timeOpen, setTimeOpen] = useState("");
  const [timeClose, setTimeClose] = useState("");

  const [startOpen, setStartOpen] = useState("");
  const [endOpen, setEndOpen] = useState("");
  const [startClose, setStartClose] = useState("");
  const [endClose, setEndClose] = useState("");
  const [areas, setAreas] = useState([]);
  const { setIsModalEditShipVisible, setPageNoti } = useContext(MedalContext);

  const {
    register: registerBanner,
    handleSubmit: handleSubmitBanner,
    reset: resetBanner,
    formState: { errors: errorsBanner },
  } = useForm();

  const {
    register: registerProduct,
    handleSubmit: handleSubmitProduct,
    reset: resetProduct,
    formState: { errors: errorsProduct },
  } = useForm();

  const onSubmitBanner = async (data) => {
    const { codeArea, banner, type } = data;
    if (!banner[0]) {
      Notiflix.Notify.failure("Vui lòng tải banner");
      return;
    }
    const formData = new FormData();
    formData.append("codeArea", codeArea);
    formData.append("type", type);
    formData.append("files", banner[0]);

    await uploadBanner(formData)
      .then((res) => Notiflix.Notify.success(res.data.message))
      .catch((err) => Notiflix.Notify.failure(err.response.data.message));
    resetBanner();
  };

  const onSubmitProduct = async (data) => {
    const { excel } = data;
    if (!excel[0]) {
      Notiflix.Notify.failure("Vui lòng tải file excel");
      return;
    }
    const formData = new FormData();
    formData.append("excel", excel[0]);

    // Thực hiện tải lên file excel
    await uploadProduct(formData)
      .then((res) => Notiflix.Notify.success("Tạo sản phẩm thành công"))
      .catch((err) => Notiflix.Notify.failure("Tạo sản phẩm thất bại"));
    resetProduct();
  };

  const handleEditShip = () => {
    setIsModalEditShipVisible(true);
  };
  // get status isRainy
  useEffect(() => {
    getListArea().then((res) => setAreas(res.data));
    getStatusRain().then((res) => {
      setStatusRain(res.data.isRainy);
    });
  }, []);

  // handle change status isRainy
  const handleRain = async () => {
    await changeStatusRain({ isRainy: !statusRain });
    await getStatusRain().then((res) => {
      setStatusRain(res.data.isRainy);
    });
  };
  // handle change status statusTime
  const handleTime = () => {
    Notiflix.Loading.pulse();
    ChangeSttTimeOpen({ isOpen: !statusTime })
      .then((res) => {
        // console.log(res);
        getInfoTimeOpen();
        Notiflix.Loading.remove();
        Notiflix.Notify.success("Cập nhật trạng thái thành công");
      })
      .catch((err) => {
        console.log(err);
        Notiflix.Loading.remove();
        Notiflix.Notify.failure("Cập nhật trạng thái thất bại");
      });
  };
  // modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // handle validate input time
  const handleStartTimeOpen = (e) => {
    setStartOpen(e.target.value);
    if (e.target.value < 0) setStartOpen(Math.abs(e.target.value));
    if (e.target.value > 24) setStartOpen(24);
    if (e.target.value.length > 2) setStartOpen(e.target.value.slice(0, 2));
    // if (e.target.value.length === 1) setStartOpen(`0${e.target.value}`)
  };

  const handleEndTimeOpen = (e) => {
    setEndOpen(e.target.value);
    if (e.target.value < 0) setEndOpen(Math.abs(e.target.value));
    if (e.target.value > 59) setEndOpen(59);
    if (e.target.value.length > 2) setEndOpen(e.target.value.slice(0, 2));
  };

  const handleStartTimeClose = (e) => {
    setStartClose(e.target.value);
    if (e.target.value < 0) setStartClose(Math.abs(e.target.value));
    if (e.target.value > 24) setStartClose(24);
    if (e.target.value.length > 2) setStartClose(e.target.value.slice(0, 2));
  };

  const handleEndTimeClose = (e) => {
    setEndClose(e.target.value);
    if (e.target.value < 0) setEndClose(Math.abs(e.target.value));
    if (e.target.value > 59) setEndClose(59);
    if (e.target.value.length > 2) setEndClose(e.target.value.slice(0, 2));
  };

  // get info time open
  const getInfoTimeOpen = () => {
    GetInfoTimeOpen()
      .then((res) => {
        // console.log(res.data);
        setStatusTime(res.data.isOpen);
        setTimeOpen(res.data.timeOpen);
        setTimeClose(res.data.timeClose);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getInfoTimeOpen();
  }, []);

  // handle submit set time open
  const handleSubmitSetTime = () => {
    // console.log(`${startOpen}:${endOpen}`);
    // console.log(`${startClose}:${endClose}`);
    const data = {
      timeOpen: `${startOpen.length === 1 ? `0${startOpen}` : startOpen}:${
        endOpen.length === 1 ? `0${endOpen}` : endOpen
      }`,
      timeClose: `${startClose.length === 1 ? `0${startClose}` : startClose}:${
        endClose.length === 1 ? `0${endClose}` : endClose
      }`,
      isOpen: false,
    };
    if (startOpen && endOpen && startClose && endClose) {
      PutTimeOpen(data)
        .then((res) => {
          Notiflix.Notify.success("Cập nhật thời gian thành công");
          getInfoTimeOpen();
          handleClose();
        })
        .catch((err) => {
          if (err.response.data.code == "INVIROMENT_TIME") {
            Notiflix.Notify.failure("Thời gian cấu hình không hợp lệ");
          } else {
            Notiflix.Notify.failure("Cập nhật thời gian không thành công");
          }
        });
    } else {
      Notiflix.Notify.warning("Vui lòng nhập đúng định dạng thời gian");
    }
  };

  return (
    <Warraper>
      {/* modal start */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalTime>
          <ModalTime.Title>Cài đặt thời gian hoạt động của App</ModalTime.Title>
          <ModalTime.WrapperList>
            <ModalTime.BoxTime>
              <ModalTime.BoxTimeTitle>Thời gian mở</ModalTime.BoxTimeTitle>
              <div className="flex gap-[5px] items-center">
                <ModalTime.InputTime
                  type="number"
                  onChange={(e) => handleStartTimeOpen(e)}
                  value={startOpen}
                />
                <span className="text-[20px] font-bold">:</span>
                <ModalTime.InputTime
                  type="number"
                  onChange={(e) => handleEndTimeOpen(e)}
                  value={endOpen}
                />
              </div>
            </ModalTime.BoxTime>
            <ModalTime.BoxTime>
              <ModalTime.BoxTimeTitle>Thời gian khóa</ModalTime.BoxTimeTitle>
              <div className="flex gap-[5px] items-center">
                <ModalTime.InputTime
                  type="number"
                  onChange={(e) => handleStartTimeClose(e)}
                  value={startClose}
                />
                <span className="text-[20px] font-bold">:</span>
                <ModalTime.InputTime
                  type="number"
                  onChange={(e) => handleEndTimeClose(e)}
                  value={endClose}
                />
              </div>
            </ModalTime.BoxTime>
          </ModalTime.WrapperList>
          <ModalTime.ListBtn>
            <ModalTime.BtnTime className="bg-[#707070]" onClick={handleClose}>
              Hủy
            </ModalTime.BtnTime>
            <ModalTime.BtnTime
              className="bg-gradient-to-b from-[#BE36E0] to-[#FF008A]"
              onClick={handleSubmitSetTime}
            >
              Xác nhận
            </ModalTime.BtnTime>
          </ModalTime.ListBtn>
        </ModalTime>
      </Modal>
      {/* modal end */}
      <Warraper.Top>
        <RainBox>
          <RainIcon src={rain} />
          <Title>Trời mưa + 20% phí ship</Title>
          <SwichBtn onClick={handleRain} src={statusRain ? turnon : turnoff} />
        </RainBox>
        <ShipBox>
          <Heading>
            <Title>Thiết lập phí ship</Title>
            <EditIcon onClick={handleEditShip} src={editIconWhite} />
          </Heading>
          <Km>
            <Span>
              Số Km đầu tiên: <TitleSpan>1Km</TitleSpan>
            </Span>
            <Span className="ml-[20px]">
              Giá tiền: <TitleSpan>10.000đ</TitleSpan>
            </Span>
          </Km>
          <Ship>
            <Span>
              Giá ship những km tiếp theo: <TitleSpan>+5.000đ</TitleSpan>
            </Span>
          </Ship>
        </ShipBox>
        <NotifyBox>
          <NotifyHeader>
            <Title>Thiết lập thông báo</Title>
            <NotifyIcon src={notifyIcon} />
          </NotifyHeader>
          <RightIcon onClick={() => setPageNoti(2)} src={rightIconbg} />
        </NotifyBox>
      </Warraper.Top>
      <Warraper.Bottom>
        <TimeBox>
          <Title className="mb-0">Thời gian hoạt động của App</Title>
          <TimeBox.WrapTimeSetting>
            <TimeBox.BoxTimeSetting>
              <span>Mở:</span>
              <span>{timeOpen}</span>
            </TimeBox.BoxTimeSetting>
            <TimeBox.BoxTimeSetting>
              <span>Đóng:</span>
              <span>{timeClose}</span>
            </TimeBox.BoxTimeSetting>
          </TimeBox.WrapTimeSetting>
          <TimeBox.Icon>
            <EditIcon onClick={handleOpen} src={editIconWhite} />
            <SwichBtn
              onClick={handleTime}
              src={statusTime ? turnon : turnoff}
            />
          </TimeBox.Icon>
        </TimeBox>

        <ShipBox className="bg-emerald-500 h-full">
          <Heading>
            <Title>Tạo sản phẩm bằng excel</Title>
          </Heading>
          <div className="pt-4">
            <form onSubmit={handleSubmitProduct(onSubmitProduct)}>
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Vui lòng tải file excel với đuôi là .xlsx
                <input
                  {...registerProduct("excel")}
                  type="file"
                  className="block w-full text-sm text-white
                  file:mr-4 file:py-2 file:px-4
                  file:rounded-full file:border-0
                  file:text-sm file:font-semibold
                  file:bg-violet-50 file:text-black
                  hover:file:bg-black hover:file:text-white
                  pt-2
                "
                />
              </label>

              <div className="flex justify-end pt-4">
                <button
                  type="submit"
                  className="bg-white hover:bg-black hover:text-white  font-bold py-2 px-4 rounded-full"
                >
                  Tạo sản phẩm
                </button>
              </div>
            </form>
          </div>
        </ShipBox>

        <NotifyBox className="bg-rose-500 h-auto">
          <NotifyHeader>
            <Title>Tạo banner</Title>
            <NotifyIcon src={banner} />
          </NotifyHeader>
          <form onSubmit={handleSubmitBanner(onSubmitBanner)}>
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Vui lòng chọn khu vực
              </label>
              <select
                id="countries"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                {...registerBanner("codeArea")}
              >
                {areas.map((area) => (
                  <option value={area.codeArea} key={area._id}>
                    {area.nameArea}
                  </option>
                ))}
              </select>
              <select
                id="type"
                className="mt-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                {...registerBanner("type")}
              >
                <option value="BANNER_HOME">Banner trang chủ</option>
                <option value="BANNER_CATEGORY">Banner loại thức ăn</option>
              </select>
            </div>
            <div className="pt-4">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Vui lòng tải banner
                <input
                  {...registerBanner("banner")}
                  type="file"
                  className="block w-full text-sm text-white
                  file:mr-4 file:py-2 file:px-4
                  file:rounded-full file:border-0
                  file:text-sm file:font-semibold
                  file:bg-violet-50 file:text-black
                  hover:file:bg-black hover:file:text-white
                  pt-2
                "
                  accept="image/*"
                />
              </label>

              <div className="flex justify-end pt-4">
                <button
                  type="submit"
                  className="bg-white hover:bg-black hover:text-white  font-bold py-2 px-4 rounded-full"
                >
                  Tạo banner
                </button>
              </div>
            </div>
          </form>
        </NotifyBox>
      </Warraper.Bottom>
      <ModalEditShop />
    </Warraper>
  );
}

export default Body;
