import React from "react";
import Home from "./components/Home";
import TabVoucher from "./components/TabVoucher";

function Voucher() {
  return (
    <>
      <Home />
      <TabVoucher/>
    </>
  );
}

export default Voucher;
