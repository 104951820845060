import React, { useContext, useState, useEffect } from 'react';
import { getListRQuser } from '../../../services/RQuserServices'
import { MedalContext } from '../../../context/Context'
import Dropdown from 'react-bootstrap/Dropdown';
import { format, parseISO } from 'date-fns'
import { Table } from 'antd';
import downIcon from '../../../assets/icon/downIcon.png';
import './styles.scss';
import ModalType from '../../../components/UI/Modals/ModalType'
import { Loading } from "notiflix";
import { changeSttRQShop } from '../../../services/RQuserServices'
import Notiflix from 'notiflix';
import { useSelector } from 'react-redux';

function TabUser() {
  const user = useSelector((state) => state.auth.user)
  console.log('Line 17 ~~~ File TabUser ~~~', user)
  const { render, setIsModalTypeVisible, setRegetnumRequest } = useContext(MedalContext);
  const handleType = (id) => {
    setId(id)
    setIsModalTypeVisible(true)
  }
  const handleCancel = (id) => {
    Notiflix.Loading.pulse();
    changeSttRQShop(id, { "status": "REFUSE", })
      .then(() => {
        Notiflix.Notify.success('Huỷ thành công')
        getlist()
        setRegetnumRequest(Math.random())
        Notiflix.Loading.remove();
      })
      .catch(() => {
        Notiflix.Notify.failure('Huỷ thất bại')
      })
  }
  const [params, setsParams] = useState({ page: '1', limit: '5', oderBy: 'createdAt', codeArea: user.codeArea })
  const [User, setUser] = useState([])
  const [panage, setPanage] = useState(1)
  const [countPage, setCountPage] = useState()
  const [id, setId] = useState('')
  const [loading, setLoading] = useState(true);

  // set loading
  useEffect(() => {
    if (loading) Loading.pulse();
    else Loading.remove();
  }, [loading]);

  // handle left btn pange
  const handleLeft = () => {
    if (panage <= 1) {
      setPanage(1)
    } else {
      setPanage(prev => prev - 1)
      setsParams({ ...params, page: panage - 1 })
    }
  }

  // handle right btn pange
  const handleRight = () => {
    if (panage >= countPage) {
      return
    } else {
      setPanage(prev => prev + 1)
      setsParams({ ...params, page: panage + 1 })
    }
  }

  // function show status
  const status = (text) => {
    switch (text) {
      case 'ACCEPT':
        return <span className="text-[#00E974]">Đã duyệt</span>
      // break;
      case 'PENDDING':
        return <span className="text-[#002BFF]">Đang chờ</span>
      // break;
      case 'REFUSE':
        return <span className="text-[#FF0000]">Đã huỷ</span>
      // break;
      default:
        break;
    }
  }

  // get list request
  const getlist = async () => {
    await getListRQuser(params)
      .then((users) => {
        console.log(users);
        setUser(users.data.data);
        setCountPage(users.data.countPage)
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      })
  }
  useEffect(() => {
    getlist()
  }, [panage, params, render])

  const columns = [
    {
      title: 'Nội dung',
      dataIndex: 'decription',
    },
    {
      title: 'Ngày',
      dataIndex: 'createdAt',
    },
    {
      title: 'Tên',
      dataIndex: 'name',
    },
    {
      title: 'Sđt',
      dataIndex: 'phone',
    },
    {
      title: 'Gmail',
      dataIndex: 'gmail',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: ([text, id]) => (
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            <div className=" w-fit flex items-center px-[10px] py-[2px] border-[1px] border-[#ccc] rounded-[3px]">
              {status(text)}
              {text == 'ACCEPT' || text == 'REFUSE' ? '' : <img className="w-[12px] h-[7px] ml-[10px]" src={downIcon} />}
            </div>
          </Dropdown.Toggle>

          {
            text == 'ACCEPT' || text == 'REFUSE' ?
              ""
              :
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleType(id)}>Duyệt yêu cầu</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => handleCancel(id)} >Huỷ yêu cầu</Dropdown.Item>
              </Dropdown.Menu>
          }
        </Dropdown>
      )
    },
  ];
  const data = [];

  User.map((user, index) => (
    data.push({
      key: index,
      decription: 'Yêu cầu mở shop',
      phone: user?.user?.phone,
      name: user?.user?.fullName,
      createdAt: user?.createdAt ? format(new Date(user?.createdAt), 'dd-MM-yyyy') : '',
      gmail: user?.user?.email,
      status: [user?.status, user?._id],
    })
  ))

  return (
    <div>
      <Table pagination={{ pageSize: 6 }} columns={columns} dataSource={data} />
      <div className="w-full flex justify-center ">
        <div className="w-fit flex text-center border-2 border-[#ccc]">
          <div
            className="text-[23px] font-bold bg-[#e0e0e0] px-[10px] text-primary hover:cursor-pointer"
            onClick={handleLeft}
          >
            &#x3c;
          </div>
          <div className="text-[23px] font-bold mx-[20px]">{panage}</div>
          <div
            className="text-[23px] font-bold bg-[#e0e0e0] px-[10px] text-primary hover:cursor-pointer"
            onClick={handleRight}
          >
            &#x3e;
          </div>
        </div>
      </div>
      <ModalType id={id} />
    </div>
  )
}

export default TabUser