import React from 'react'
import { Space, Table, Tag } from 'antd';

function TableStatiscal({ props }) {
    var formatter = new Intl.NumberFormat('vi', {
        style: 'currency',
        currency: 'VND',
    });
    // create datatable
    const dataTable = props && [
        {
            status: 'Đã giao',
            backgroundColor: '#01FF0A',
            totalOrder: props[0]?.totalOrder,
            totalPrice: props[0]?.totalPrice,
            totalShip: props[0]?.totalShip,
        },
        {
            status: 'Đã huỷ',
            backgroundColor: '#FF1F1F',
            totalOrder: props[1]?.totalOrder,
            totalPrice: props[1]?.totalPrice,
            totalShip: props[1]?.totalShip,
        },
        {
            status: 'Đang chờ',
            backgroundColor: '#6400FA',
            totalOrder: props[2]?.totalOrder,
            totalPrice: props[2]?.totalPrice,
            totalShip: props[2]?.totalShip,
        },
        {
            status: 'Chờ lấy hàng',
            backgroundColor: '#F67A28',
            totalOrder: props[3]?.totalOrder,
            totalPrice: props[3]?.totalPrice,
            totalShip: props[3]?.totalShip,
        },
        {
            status: 'Đang giao',
            backgroundColor: '#F9128F',
            totalOrder: props[4]?.totalOrder,
            totalPrice: props[4]?.totalPrice,
            totalShip: props[4]?.totalShip,
        },
    ]
    const columns = [
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            render: (_, { status }) => (
                <div className="flex items-center">
                    <div className="w-[20px] h-[20px] rounded-sm mr-2" style={{ backgroundColor: status.color, }}></div>
                    <div>{status.status}</div>
                </div>
            ),
        },
        {
            title: 'Số đơn',
            dataIndex: 'orderNum',
            key: 'orderNum',
        },
        {
            title: 'Tổng COD',
            dataIndex: 'cod',
            key: 'orderNum',
        },
        {
            title: 'Tổng tiền hàng',
            dataIndex: 'totalMoney',
            key: 'totalMoney',
        },
        {
            title: 'Tổng tiền ship',
            dataIndex: 'totalShip',
            key: 'totalShip',
        },
    ];

    const data = [];
    dataTable?.map((item, i) => {
        data.push({
            key: `${i}`,
            status: { status: item?.status, color: item?.backgroundColor },
            orderNum: item?.totalOrder,
            cod: '0đ',
            totalMoney: formatter.format(item?.totalPrice),
            totalShip: formatter.format(item?.totalShip),
        })
    })

    return (
        <div className="tableChart h-[400px]">
            <Table columns={columns} dataSource={data} />
        </div>
    )
}

export default TableStatiscal