import React, { useEffect, useContext } from 'react'

import Header from './Components/Header';
import Pane from './Components/Pane';
import { MedalContext } from "../../context/Context"

function OrderManager() {
  const $ = document.querySelector.bind(document);
  const $$ = document.querySelectorAll.bind(document);
  const { setStatus, status, reRender } = useContext(MedalContext)

  useEffect(() => {
    const TabUi = () => {
      $$('.tabItem').forEach((item, index) => {
        item.addEventListener('click', () => {
          // handle tabItem
          $('.tabItem.active').classList.remove('active')
          item.classList.add('active');
        })
      })
    }
    TabUi();
  })

  useEffect(() => {
    return () => {
      setStatus({ ...status, status: '' })
    }
  }, [reRender])

  return (
    <div className='h-full flex flex-col'>
      <Header />
      <Pane />
    </div>
  )
}

export default OrderManager