import React, { useContext, useEffect, useState } from 'react'
import './styles.scss';
const $ = document.querySelector.bind(document);
const $$ = document.querySelectorAll.bind(document);

import {
  Li,
  Ul,
  TxtButton,
  LogoBtn,
  Img,
} from './LeftPage.styles'

import logo from '../../assets/icons/logo.jpg'
import box from '../../assets/icon/box.png'
import boxActive from '../../assets/icon/boxActive.png'
import user from '../../assets/icon/user.png'
import userActive from '../../assets/icon/userActive.png'
import quality from '../../assets/icon/quality.png'
import qualityActive from '../../assets/icon/qualityActive.png'
import pieChart from '../../assets/icon/pieChart.png'
import pieChartActive from '../../assets/icon/pieChartActive.png'
import setting from '../../assets/icon/setting.png'
import settingActive from '../../assets/icon/settingActive.png'
import voucher from '../../assets/icon/voucher.png'
import voucherActive from '../../assets/icon/voucherActive.png'
import { getNumRQuser } from '../../services/RQuserServices'
import { useSelector } from 'react-redux'
import { MedalContext } from '../../context/Context'

function LeftPage() {
  const [numRQ, setNumRQ] = useState(0)
  const { active, setactive, regetnumRequest, setRegetnumRequest } = useContext(MedalContext);
  useEffect(() => {
    $$('.navTab').forEach((item, index) => {
      item.addEventListener('click', () => {
        $(".navTab.active").classList.remove('active')
        item.classList.add('active')
      })
    })
  })
  useEffect(() => {
    getNumRQuser('PENDDING')
      .then((res) => {
        setNumRQ(res.data.data)
      })
  }, [regetnumRequest])

  const role = useSelector(state => state.role.role)
  // console.log(role);
  return (
    <div className="bg-primary h-full">
      <div className="flex justify-center mb-[18px] pt-[19px]">
        <LogoBtn src={logo} />
      </div>
      <Ul>
        <Li className="navTab active" onClick={() => setactive(1)}><Img src={active == 1 ? boxActive : box} /><TxtButton>Quản lý đơn hàng</TxtButton></Li>
        {(role == 'ADMIN' || role == 'SUPERADMIN') && <>
          <Li className="navTab" onClick={() => setactive(2)}><Img src={active == 2 ? userActive : user} /><TxtButton>Quản lý tài khoản</TxtButton></Li>
          <Li className="navTab" onClick={() => setactive(3)}><Img src={active == 3 ? qualityActive : quality} />
            <TxtButton onClick={() => setRegetnumRequest(Math.random())} >
              Xét duyệt yêu cầu
              <span className='ml-[5px] bg-white text-black px-[5px] py-[2px] rounded-sm'>{numRQ}</span>
            </TxtButton>
          </Li>
          <Li className="navTab" onClick={() => setactive(4)}><Img src={active == 4 ? pieChartActive : pieChart} /><TxtButton>Thống kê</TxtButton></Li>
          <Li className="navTab" onClick={() => setactive(6)}><Img src={active == 6 ? voucherActive : voucher} /><TxtButton>Quản lý ưu đãi</TxtButton></Li>

          <Li className="navTab" onClick={() => setactive(5)}><Img src={active == 5 ? settingActive : setting} /><TxtButton>Cài đặt hệ thống</TxtButton></Li>

        </>}
      </Ul>
    </div>
  )
}

export default LeftPage