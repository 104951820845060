import React, {useContext} from 'react'
import Home from './components/Home'
import PageNotify from './components/PageNotify'
import {MedalContext} from '../../context/Context'
import {
  Routes,
  Route,
} from "react-router-dom";

export default function SystemInstallation() {
  const {pageNoti} = useContext(MedalContext)
  return (
    <div>
      {
        pageNoti == 1 ?
        <Home /> :
        <PageNotify />
      }
    </div>
  )
}
