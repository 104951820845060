import AxiosClient from "./axiosClient";

const API_ENDPOINT = "noti/";

export const getNotifications = (params) => {
  return AxiosClient.get(API_ENDPOINT, { params: params});
};
export const getNotificationsInfo = (id) => {
  return AxiosClient.get(API_ENDPOINT + id);
};
export const deleteNotifications = (id) => {
  return AxiosClient.delete(API_ENDPOINT + id);
};
export const postNotifications = (data) => {
  console.log(data);
  return AxiosClient.post(API_ENDPOINT,data,{
    headers: { 'content-type': 'multipart/form-data'}
  });
};
export const putNotifications = (data,id) => {
  return AxiosClient.put(API_ENDPOINT+id,data,{
    headers: { 'content-type': 'multipart/form-data'}
  });
};
