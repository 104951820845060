import AxiosClient from "./axiosClient";

export const getListShipper = (params) => {
    return AxiosClient.get('shipper', { params: params })
}

export const getListShipperByArea = (params) => {
    return AxiosClient.get('shipper/', { params: params })
}

export const upPointShipper = (data) => {
    return AxiosClient.post('shipper/addPoints', data)
}