import AxiosClient from "./axiosClient";

const API_ENDPOINT = "product/";

export const getProduct = (params) => {
  return AxiosClient.get(API_ENDPOINT, {
    params: params,
  });
};

export const getSize = (params) => {
  return AxiosClient.get(`size/${params.id}`);
};
export const getTopping = (params) => {
  return AxiosClient.get(`topping/${params.id}`);
};

export const createOrder = (data) => {
  return AxiosClient.post("order", data);
};

export const uploadProduct = (data) => {
  return AxiosClient.post(API_ENDPOINT + "excel", data);
};
