import AxiosClient from "./axiosClient";

const API_ENDPOINT = "analytical/";

export const getReport = (params) => {
  return AxiosClient.get(API_ENDPOINT + "admin", { params });
};
export const getReportShop = (params) => {
  return AxiosClient.get(API_ENDPOINT + "shop/all", { params: params });
};
export const getReportShopShop = (params) => {
  return AxiosClient.get(API_ENDPOINT + "shop/shop", { params: params });
};
export const getReportUser = (params) => {
  return AxiosClient.get(API_ENDPOINT + "shop/user", { params: params });
};
export const getLinkReport = (params) => {
  return AxiosClient.get(API_ENDPOINT + "admin/export-excel", { params: params,responseType: "blob" });
};