import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  records: [
    {
      code: "USER_MANAGER",
      title: "Quản lý người dùng",
      note: "Quyền này có thể tạo user",
    },
    {
      code: "KNIFE_MANAGER",
      title: "Quản lý dao",
      note: "Quyền này có thể  quản lý dao",
    },
    {
      code: "CABINET_MANAGER",
      title: "Quản lý tủ",
      note: "Quyền này quản lý tủ",
    },
    {
      code: "BASE",
      title: "Mặc định",
      note: "Quyền cơ bản",
    },
    {
      code: "BORROW",
      title: "Mượn",
      note: "Quyền mượn",
    },
    {
      code: "ALWAY",
      title: "Lấy",
      note: "Quyền lấy",
    },
    {
      code: "SUPPLIER_MANAGER",
      title: "Quản lý nhà cung cấp",
      note: "Quyền quản lý nhà cung cấp",
    },
    {
      code: "REPORT_MANAGER",
      title: "Báo cáo",
      note: "Quyền xem báo cáo",
    },
  ],
};

const permissionSlice = createSlice({
  name: "supplier",
  initialState,
  reducers: {},
});

export default permissionSlice.reducer;
