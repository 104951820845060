import AxiosClient from "./axiosClient";

const API_ENDPOINT = "/voucher/";


export const getListVoucher = async(params)=> {
  return await AxiosClient.get(API_ENDPOINT,{ params: params});
};
export const deleteVoucher = (id) => {
  return AxiosClient.delete(`${API_ENDPOINT}${id}`);
};
export const postVoucher = (data)=>{
  return AxiosClient.post(`${API_ENDPOINT}`,data);
}
