import React, { useContext, useEffect, useState } from 'react'
import './styles.scss';

import {
    FormWrapper,
    Navlist,
    NavItem,
    TxtNav,
    IconNav,
} from './Navbar.styles'
import { MedalContext } from "../../../context/Context"
import { getListOrderShopWithStt, getListOrderWithStt } from '../../../services/orderServices'
import { useSelector } from "react-redux";

function Navbar() {
    const { status, setStatus, setRenderPage, reRender } = useContext(MedalContext)
    const [all, setAll] = useState('')
    const [New, setNew] = useState('')
    const [wait, setWait] = useState('')
    const [deliver, setDeliver] = useState('')
    const [done, setDone] = useState('')
    const [cancel, setCancel] = useState('')
    const onLoad = useSelector(state => state.render);
    const role = useSelector(state => state.role.role);
    const user = useSelector(state => state.auth.user);
    const selectedCodeArea = useSelector(state => state.area.codeArea);

    // get list to get total order
    useEffect(() => {
        if (role == 'SUPERADMIN') {
            getListOrderWithStt({ ...status, status: '', codeArea: selectedCodeArea })
                .then((oders) => {
                    setAll(oders.data.totalElement);
                })
            getListOrderWithStt({ ...status, status: 'PENDDING', codeArea: selectedCodeArea })
                .then((oders) => {
                    setNew(oders.data.totalElement);
                })
            getListOrderWithStt({ ...status, status: 'WAITING_FOR_PRODUCT', codeArea: selectedCodeArea })
                .then((oders) => {
                    setWait(oders.data.totalElement);
                })
            getListOrderWithStt({ ...status, status: 'DELIVERY', codeArea: selectedCodeArea })
                .then((oders) => {
                    setDeliver(oders.data.totalElement);
                })
            getListOrderWithStt({ ...status, status: 'COMPLETED', codeArea: selectedCodeArea })
                .then((oders) => {
                    setDone(oders.data.totalElement);
                })
            getListOrderWithStt({ ...status, status: 'CANCELLED', codeArea: selectedCodeArea })
                .then((oders) => {
                    setCancel(oders.data.totalElement);
                })
        }
        else if (role == 'ADMIN') {
            getListOrderWithStt({ ...status, status: '', codeArea: user.codeArea })
                .then((oders) => {
                    setAll(oders.data.totalElement);
                })
            getListOrderWithStt({ ...status, status: 'PENDDING', codeArea: user.codeArea })
                .then((oders) => {
                    setNew(oders.data.totalElement);
                })
            getListOrderWithStt({ ...status, status: 'WAITING_FOR_PRODUCT', codeArea: user.codeArea })
                .then((oders) => {
                    setWait(oders.data.totalElement);
                })
            getListOrderWithStt({ ...status, status: 'DELIVERY', codeArea: user.codeArea })
                .then((oders) => {
                    setDeliver(oders.data.totalElement);
                })
            getListOrderWithStt({ ...status, status: 'COMPLETED', codeArea: user.codeArea })
                .then((oders) => {
                    setDone(oders.data.totalElement);
                })
            getListOrderWithStt({ ...status, status: 'CANCELLED', codeArea: user.codeArea })
                .then((oders) => {
                    setCancel(oders.data.totalElement);
                })
        }
        else if (role == 'SHOP') {
            getListOrderShopWithStt({ ...status, status: '', codeArea: user.codeArea })
                .then((oders) => {
                    setAll(oders.data.totalElement);
                })
            getListOrderShopWithStt({ ...status, status: 'PENDDING', codeArea: user.codeArea })
                .then((oders) => {
                    setNew(oders.data.totalElement);
                })
            getListOrderShopWithStt({ ...status, status: 'WAITING_FOR_PRODUCT', codeArea: user.codeArea })
                .then((oders) => {
                    setWait(oders.data.totalElement);
                })
            getListOrderShopWithStt({ ...status, status: 'DELIVERY', codeArea: user.codeArea })
                .then((oders) => {
                    setDeliver(oders.data.totalElement);
                })
            getListOrderShopWithStt({ ...status, status: 'COMPLETED', codeArea: user.codeArea })
                .then((oders) => {
                    setDone(oders.data.totalElement);
                })
            getListOrderShopWithStt({ ...status, status: 'CANCELLED', codeArea: user.codeArea })
                .then((oders) => {
                    setCancel(oders.data.totalElement);
                })
        }
    }, [status, reRender, onLoad, selectedCodeArea])
    return (
        <FormWrapper>
            <Navlist onClick={() => setRenderPage(Math.random())}>
                <NavItem className="tabItem active" onClick={() => setStatus({ ...status, status: '', page: '1' })}>
                    <TxtNav>Tất cả đơn hàng</TxtNav>
                    <IconNav>{all}</IconNav>
                </NavItem>
                <NavItem className="tabItem" onClick={() => setStatus({ ...status, status: 'PENDDING', page: '1' })}>
                    <TxtNav>Đơn mới</TxtNav>
                    <IconNav>{New}</IconNav>
                </NavItem>
                <NavItem className="tabItem" onClick={() => setStatus({ ...status, status: 'WAITING_FOR_PRODUCT', page: '1' })}>
                    <TxtNav>Chờ lấy hàng</TxtNav>
                    <IconNav>{wait}</IconNav>
                </NavItem>
                <NavItem className="tabItem" onClick={() => setStatus({ ...status, status: 'DELIVERY', page: '1' })}>
                    <TxtNav>Đang vận chuyển</TxtNav>
                    <IconNav>{deliver}</IconNav>
                </NavItem>
                <NavItem className="tabItem" onClick={() => setStatus({ ...status, status: 'COMPLETED', page: '1' })}>
                    <TxtNav>Hoàn thành</TxtNav>
                    <IconNav>{done}</IconNav>
                </NavItem>
                <NavItem className="tabItem" onClick={() => setStatus({ ...status, status: 'CANCELLED', page: '1' })}>
                    <TxtNav>Đã huỷ</TxtNav>
                    <IconNav>{cancel}</IconNav>
                </NavItem>
            </Navlist>
        </FormWrapper>
    )
}

export default Navbar