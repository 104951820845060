import React, { useContext, useState, useEffect } from 'react';
import Notiflix from 'notiflix';
import { Modal } from 'antd';
import { MedalContext } from '../../../context/Context'
import downIcon from '../../../assets/icon/downIcon.png';
import Dropdown from 'react-bootstrap/Dropdown';
import { getInfoShop, changeInfoShop } from '../../../services/shopServices'
import { format } from 'date-fns'

import {
    FormWrapper,
    Item,
    ItemTitle,
    FormInput,
    Input,
    InputTxt,
    StatusBtn,
    StatusBtnTitle,
    StatusBtnImg,
    FormStatus,
    FormBtn,
    Button

} from './Modals.styles'
import './Modals.scss';

function ModalEditShop({ id }) {

    const { isModalEditMangerVisible, setIsModalEditMangerVisible, setReRender } = useContext(MedalContext)
    const [shopInfo, setShopInfo] = useState()
    const [shopInfoName, setShopInfoName] = useState()
    const [shopInfoAddr, setShopInfoAddr] = useState()
    const [shopInfoDes, setShopInfoDes] = useState()
    const [shopInfoStt, setShopInfoStt] = useState()
    const [shopInfoTime, setShopInfoTime] = useState()
    // handle ok
    const handleOk = () => {
        setIsModalEditMangerVisible(false);
    };
    // handle cancel
    const handleCancel = () => {
        setIsModalEditMangerVisible(false);
    };
    // handle change info shop  
    const data = {
        "name": shopInfoName,
        "description": shopInfoDes,
        "address": {
            "address": shopInfoAddr?.address,
            "location": shopInfoAddr?.location
        },
        "time": shopInfoTime
    }
    const handleChangeInfoShop = async () => {
        await changeInfoShop(id, data)
            .then((res) => {
                setReRender(Math.random())
                setIsModalEditMangerVisible(false);
                Notiflix.Notify.success('Cập nhật thành công')
            })
    }
    // get info shop
    useEffect(() => {
        if (id) {
            getInfoShop(id)
                .then((res) => {
                    console.log(res.data);
                    setShopInfo(res.data);
                    setShopInfoName(res.data.name)
                    setShopInfoAddr(res.data.address)
                    setShopInfoDes(res.data.description)
                    setShopInfoStt(res.data.status)
                    setShopInfoTime(res.data.time)
                })
        } else {
            return
        }
    }, [id])


    return (
        <Modal
            title="Chỉnh sửa thông tin shop"
            visible={isModalEditMangerVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
            width={900}
        >
            <FormWrapper>
                <Item className="disabled">
                    <ItemTitle>Số điện thoại</ItemTitle>
                    <FormInput>
                        <Input value={shopInfo?.user.phone} disabled />
                    </FormInput>
                </Item>
                <Item>
                    <ItemTitle>Tên shop </ItemTitle>
                    <FormInput>
                        <Input value={shopInfoName} onChange={(e) => setShopInfoName(e.target.value)} />
                    </FormInput>
                </Item>
                <Item className="disabled">
                    <ItemTitle>Quản lý</ItemTitle>
                    <FormInput>
                        <Input value={shopInfo?.user.fullName} disabled />
                    </FormInput>
                </Item>
                <Item className="disabled">
                    <ItemTitle>Địa chỉ</ItemTitle>
                    <FormInput>
                        <InputTxt value={shopInfoAddr?.address} disabled />
                    </FormInput>
                </Item>
                <Item className="disabled">
                    <ItemTitle>Email</ItemTitle>
                    <FormInput>
                        <InputTxt value={shopInfo?.user.email} disabled />
                    </FormInput>
                </Item>
                <Item>
                    <ItemTitle>Mô tả</ItemTitle>
                    <FormInput>
                        <InputTxt value={shopInfoDes} onChange={(e) => setShopInfoDes(e.target.value)} />
                    </FormInput>
                </Item>
                <Item>
                    <ItemTitle>Trạng thái</ItemTitle>
                    <FormStatus>
                        <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                <StatusBtn>
                                    <StatusBtnTitle className={`${shopInfoStt == 'ACTIVE' ? ' text-[#00E974]' : ' text-[#FF1B1B]'}`}>
                                    {shopInfoStt == 'ACTIVE' ? 'Đang mở' : 'Đang khoá'}
                                    </StatusBtnTitle>
                                    <StatusBtnImg src={downIcon} />
                                </StatusBtn>
                            </Dropdown.Toggle>

                            {/* <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">
                                    <span className="text-[#0A00FF] font-bold text-[14px]">Chờ xác nhận</span>
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item href="#/action-2">
                                    <span className="text-[#F9128F] font-bold text-[14px]">Chờ lấy hàng</span>
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item href="#/action-3">
                                    <span className="text-[#F67A28] font-bold text-[14px]">Đang giao</span>
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item href="#/action-3">
                                    <span className="text-[#F67A28] font-bold text-[14px]">Đã giao</span>
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item href="#/action-3">
                                    <span className="text-[#F41D1D] font-bold text-[14px]">Huỷ đơn</span>
                                </Dropdown.Item>
                            </Dropdown.Menu> */}
                        </Dropdown>
                    </FormStatus>
                </Item>
                {/* <Item>
                    <ItemTitle>Loại hình</ItemTitle>
                    <FormStatus>
                        <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                <StatusBtn>
                                    <StatusBtnTitle>Ăn vặt</StatusBtnTitle>
                                    <StatusBtnImg src={downIcon} />
                                </StatusBtn>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-3">
                                    <span className="font-bold text-[14px]">Tạp hoá</span>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </FormStatus>
                </Item> */}
                <FormBtn>
                    <Button onClick={handleChangeInfoShop}>Cập nhật</Button>
                </FormBtn>
            </FormWrapper>
        </Modal>
    )
}

export default ModalEditShop