import React, { useContext, useState, useEffect } from "react";
import { getListShop, changeSttShop } from "../../../services/shopServices";
import Dropdown from "react-bootstrap/Dropdown";
import { MedalContext } from "../../../context/Context";
import { Table } from "antd";
import lock from "../../../assets/icon/lock.png";
import unLock from "../../../assets/icon/unLock.png";
import lockGreen from "../../../assets/icon/lockGreen.png";
import downIcon from "../../../assets/icon/downIcon.png";
import searchIcon from "../../../assets/icon/searchIcon.png";
import dropdown from "../../../assets/icon/dropDown.png";
import editIcon from "../../../assets/icon/editIcon.png";
import { useForm } from "react-hook-form";
import "./styles.scss";
import { Input, InputForm, ImgForm } from "./TabUser.styles";
import { getListShipper, upPointShipper, getListShipperByArea } from "../../../services/shipperServices";
import { Modal, Button } from "antd";
import Notiflix from "notiflix";
import { useSelector } from "react-redux";

function TabShipper() {
   // const { setIsModalEditMangerVisible, reRender } = useContext(MedalContext);
   // Redux
   const user = useSelector((state) => state.auth.user);
   const codeArea = useSelector((state) => state.area.codeArea)
   const role = useSelector((state) => state.role.role)

   const [params, setsParams] = useState({
      codeArea: role == "SUPERADMIN" ? codeArea : user.codeArea,
      page: '1',
      limit: '10',
      oderBy: 'createdAt'
   })
   const [shippers, setShippers] = useState([]);
   const [panage, setPanage] = useState(1);
   const [countPage, setCountPage] = useState();
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [currentPhoneShipper, setCurrentPhoneShipper] = useState();
   const [currentPointShipper, setCurrentPointShipper] = useState();

   // handle left btn pange
   const handleLeft = () => {
      if (panage <= 1) {
         setPanage(1);
      } else {
         setPanage((prev) => prev - 1);
         setsParams({ ...params, page: panage - 1 });
      }
   };

   // handle right btn pange
   const handleRight = () => {
      if (panage >= countPage) {
         return;
      } else {
         setPanage((prev) => prev + 1);
         setsParams({ ...params, page: panage + 1 });
      }
   };

   // get list shipper
   const getlist = async () => {
      await getListShipperByArea(params)
         .then((data) => {
            setShippers(data.data.shippers);
            setCountPage(data.data.countPage);
         })
         .catch((err) => {
            console.log(err);
         });
   };

   // get list shipper
   useEffect(() => {
      getlist();
   }, [panage, params]);

   useEffect(() => {
      if (codeArea == null || codeArea == "" || codeArea == undefined && role == 'SUPERADMIN') {
         setsParams({ ...params, codeArea: undefined })
         return;
      }
      setsParams({ ...params, codeArea: role == "SUPERADMIN" ? codeArea : user.codeArea })
   }, [codeArea])

   // table shop
   const data = [];

   shippers.map((shop, index) =>
      data.push({
         key: index,
         phone: shop?.phone,
         fullName: shop?.fullName,
         point: shop?.point,
         upPoint: [shop?.phone, shop?.point],
      })
   );

   const columns = [
      {
         title: "Sđt",
         dataIndex: "phone",
      },
      {
         title: "Tên shipper",
         dataIndex: "fullName",
      },
      {
         title: "Điểm",
         dataIndex: "point",
      },
      {
         title: "Nạp điểm",
         dataIndex: "upPoint",
         render: ([phone, point]) => (
            <Button type="primary" onClick={(e) => OpenModalUpPoint(phone)}>
               Nạp
            </Button>
         ),
      },
   ];

   const OpenModalUpPoint = (phone) => {
      setCurrentPhoneShipper(phone);
      setIsModalOpen(true);
   };

   const handleCancel = () => {
      setIsModalOpen(false);
   };

   const handleUpPoint = () => {
      upPointShipper({
         phone: currentPhoneShipper,
         point: Number(currentPointShipper),
      })
         .then((res) => {
            console.log(res);
            setCurrentPointShipper("");
            setIsModalOpen(false);
            getlist();
            Notiflix.Notify.success('Nạp điểm thành công')
         })
         .catch((err) => {
            console.log(err);
            Notiflix.Notify.failure('Có lỗi xảy ra')
         });
   };

   return (
      <>
         <div className="manager-pane flex-1 flex flex-col overflow-y-scroll">
            <div className="flex flex-col h-full">
               <div className="flex-1 h-full overflow-y-scroll">
                  <Table
                     pagination={{ pageSize: 10 }}
                     columns={columns}
                     dataSource={data}
                  />
               </div>

               <div className="w-full flex justify-center relative px-[20px] py-[20px]">
                  <div className="w-fit flex text-center border-2 border-[#ccc]">
                     <div
                        className="text-[23px] font-bold bg-[#e0e0e0] px-[10px] text-primary hover:cursor-pointer"
                        onClick={handleLeft}
                     >
                        &#x3c;
                     </div>
                     <div className="text-[23px] font-bold mx-[40px]">
                        {countPage ? `${panage}/${countPage}` : panage}
                     </div>
                     <div
                        className="text-[23px] font-bold bg-[#e0e0e0] px-[10px] text-primary hover:cursor-pointer"
                        onClick={handleRight}
                     >
                        &#x3e;
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <Modal
            title="Nạp điểm cho shipper"
            visible={isModalOpen}
            onOk={handleUpPoint}
            onCancel={handleCancel}
         >
            <Input
               placeholder="Nhập điểm"
               value={currentPointShipper}
               onChange={(e) => setCurrentPointShipper(e.target.value)}
            />
         </Modal>
      </>
   );
}

export default TabShipper;
