import tw from 'tailwind-styled-components'

export const Input = tw.input`
  px-[10px]
  py-[7px]
  text-[15px]
  flex-1
  border-2
  border-[#ccc]
  rounded-tl-lg
  rounded-bl-lg
  outline-none
`
export const InputForm = tw.div`
  w-[300px]
  flex
  items-cennter
  justify-between
  mt-[20px] 
  ml-[20px] 
`
export const ImgForm = tw.div`
  flex 
  items-center 
  px-[22px] 
  bg-primary
  rounded-tr-lg
  rounded-br-lg
  hover:cursor-pointer
`