import tw from 'tailwind-styled-components'

export const FormWrapper = tw.div`
    h-[500px]
    w-full
    px-[20px]
`
export const BtnImg = tw.div`
    flex
    justify-center
    hover:cursor-pointer
`
export const Img = tw.img`
    w-[140px]
    max-h-[180px]
`
export const BoxTitle = tw.div`
`
export const BoxButton = tw.div`
    flex
    w-full
    justify-center
    mt-[20px]
`
export const Title = tw.div`
    text-[17px]
    font-bold
    mb-[7px]
`
export const Input = tw.input`
    w-full
    border-[1px]
    border-[#878484]
    rounded-[7px]
    outline-none
    px-[10px]
    py-[5px]
    text-[17px]
`
export const Textarea = tw.textarea`
    w-full
    h-[100px]
    border-[1px]
    border-[#878484]
    rounded-[7px]
    text-[17px]
    px-[10px]
    py-[5px]
    outline-none
`
export const Note = tw.span`
    text-primary
    text-[13px] 
`
export const Button = tw.button`
    text-[17px]
    text-white
    px-[30px]
    py-[10px]
    bg-gradient-to-r from-[#BF36DF] to-[#FF018B]
    rounded-[7px]
`